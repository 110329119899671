<template>
  <div class='package_sizes_list'>
    <template v-for='size in sizeList'>
      <div class='package_size' :class="{ 'active': size.tallaId === sizeSelectedId }" @click='handleClick(size)'>

        <div v-if="size.estado && size.codigo !== 'P'" class='package_size--content'>

          <Icon v-if="['XXS'].includes(size.codigo)" name='Email-document-2' :size='32' />
          <Icon v-if="['XS'].includes(size.codigo)" name='Delivery-Box' :size='32' />
          <Icon v-if="['S', 'M'].includes(size.codigo)" name='Delivery-Box-1' :size='32' />
          <Icon v-if="['L', 'XL'].includes(size.codigo)" name='Delivery-Box-2' :size='36' />

          <div>
            <div class='text-xs-bold'>{{ size.alto }} x {{ size.ancho }} x {{ size.profundidad }} cm</div>
            <div class='text-xs-bold'>hasta {{ size.pesoMaximo }} kg</div>
            <div class='text-s-light'>{{ size.descripcion }}</div>
          </div>

        </div>

        <div v-if="size.estado && size.codigo === 'P'" class='package_size--content'>
          <Icon name='Delivery-Box' variant='bulk' :size='32' />
          <div>
            <div class='text-xs-bold'>Hazlo único: suelta las cifras. 🛠️</div>
            <div class='text-s-light'>Personalizado</div>
          </div>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    sizeSelectedId: { required: true },
    sizeList: { required: true, default: [] },
  },
  methods: {
    handleClick(size) {
      this.$emit('input', size.tallaId)
      this.$emit('onSizeSelected', size)
    }
  },
}
</script>

<style lang='scss' scoped>
.package_sizes_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;

  .package_size {
    cursor: pointer;

    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 0 1px #D0D0D0;
    border-radius: 12px;

    transition: .2s;

    &:not(.active):hover {
      background-color: #D0D0D0;
      box-shadow: 0 0 0 1px #D0D0D0;
    }

    &.active {
      box-shadow: 0 0 0 1.5px #121212;
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 10px;
      gap: 5px;
    }
  }
}
</style>