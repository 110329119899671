<!-- eslint-disable max-len -->
<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container add_package_slide px-0">

      <!-- @start Header-->
      <div class="header_add_packagev2">
        <div class="c-btn-popup-slide-close back_button" :data-popup-id="id">
          <svg width="32" height="32" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000">
            <path d="m15 6-6 6 6 6" stroke="#000" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="header_title">Agregar paquete</div>
      </div>

      <!-- @start content-->
      <div class="body_add_packagev2">
        <div class="sections_container">

          <!-- Método de pago -->
          <div class="title_section-and-section ">
            <FormSectionTitle title="Método de pago" />
            <div style="margin: -12px -12px 0 -12px;">
              <CustomRadioButton
                radioId="opcion1"
                :inputValue="false"
                v-model="isShippingPaidAtDestination"
                label="Pago en línea"
              />
              <CustomRadioButton
                radioId="opcion2"
                :inputValue="true"
                v-model="isShippingPaidAtDestination"
                label="Pago al recoger el paquete"
                disabled
                helperText='Disponible cuando el destino es un punto Sharf'
              />
            </div>
          </div>

          <!-- Lugar de entrega -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Lugar de entrega" />
            <div class="delivery-place-inputs">
              <CustomSearchSelect
                @setSelectedDisctrict="setDestinationDistrictData"
                ref="CustomSelectComponentRef"
                v-model="destinationDistrictId"
                :repoSearch="getScharffDistrics"
                label="Distrito"
                placeholder="Busca por departamento, provincia o distrito"
                errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="tryToAddPackage && !destinationDistrictId"
              />
              <InputText
                label="Dirección"
                :maxLength="150"
                placeholder="Calle/avenida, número, departamento, etc."
                v-model="address"
                missingErrorMessage="Ingresa una dirección."
                :showEmptyInputError="tryToAddPackage && !address"
                :showExtraMessage="true"
                extraMessage="*Todos nuestros recojos y entregas se realizan en el primer piso de la vivienda"
              />
              <InputText
                isOptional
                label="Referencia (opcional)"
                :maxLength="150"
                placeholder="Manzana, lote, alrededores"
                v-model="reference"
                missingErrorMessage="Ingresa una referencia."
              />
            </div>
          </div>

          <!-- Datos de quien recibe -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Datos de quien recibe" />
            <div class="delivery-place-inputs">
              <DestinationContactButton
                @click="openContactModal('destination'), trackEvent('click_programado_paso_3_contacto_btn', 'programado')"
                :destinationContact="DestinationContact"
                missingErrorMessage="Escoge un contacto."
              />
              <div v-if="tryToAddPackage && !DestinationContact" style="margin-top: -15px;">
                <ErrorMessage
                  message="Escoge un contacto."
                />
              </div>
            </div>
          </div>

          <!-- Características del paquete -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Características del paquete" />
            <div class="delivery-place-inputs">
              <InputText
                label="Descripción del producto"
                :maxLength="150"
                placeholder="¿Qué es lo que envías?"
                v-model="productDescription"
                missingErrorMessage="Ingresa la descripción del producto."
                :showEmptyInputError="tryToAddPackage && !productDescription"
              />
              <CustomCheckbox
                checkboxId="fragile-product"
                v-model="isAFragileProduct"
                label="Producto frágil"
              />
              <div class="package_measurements">
                <PackageSizes :sizeList='sizeList' :sizeSelectedId='sizeSelected.tallaId' @onSizeSelected='handleSizeSelected' />
                <div v-if="sizeSelected.codigo === 'P'" class="attributes">
                  <InputPackageAttribute
                    label="Peso"
                    measure="kg"
                    v-model="packageWeight"
                    missingErrorMessage="Ingresa el Peso."
                    :showEmptyInputError="tryToAddPackage && !packageWeight"
                  />
                </div>
                <ErrorMessage v-show="exceedsWeightLimit" :message="`El peso máximo permitido por paquete en el servicio programados es ${maxWeight} kg.`" />
                <div v-if="sizeSelected.codigo === 'P'" style="padding-top: 16px;" class="attributes">
                  <InputPackageAttribute
                    label="Largo"
                    measure="cm"
                    v-model="packageWidth"
                    missingErrorMessage="Ingresa el Largo."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageWidth"
                  />
                  <InputPackageAttribute
                    label="Alto"
                    measure="cm"
                    v-model="packageHeight"
                    missingErrorMessage="Ingresa el Alto."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageHeight"
                  />
                  <InputPackageAttribute
                    label="Ancho"
                    measure="cm"
                    v-model="packageDeep"
                    missingErrorMessage="Ingresa el Ancho."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageDeep"
                  />
                </div>
                <ErrorMessage
                  v-if="
                    packageWidth <= maxLength &&
                    packageHeight <= maxLength &&
                    packageDeep <= maxLength
                  "
                  :message="volumetricWeightMsg"
                  v-show="exceedsVolumetricWeight"
                />
                <div class="attributes" style="padding-top: 16px">
                  <InputPackageAttribute
                    label="Valor del producto"
                    measure="S/"
                    v-model="packageValue"
                    missingErrorMessage="Ingresa el valor del producto."
                    :showEmptyInputError="tryToAddPackage && !packageValue"
                    isCurrency
                  />
                </div>
              </div>
              <div style="padding-top: 8px">
                <InformativeMessage message="Tenemos garantía de hasta S/ 1000." />
              </div>
            </div>
          </div>
          <!-- <div v-if="allowSecondAttempt">
            <div style="display: flex; flex-direction: column; gap: 4px;">
              <div class="text-l-bold">En caso el destinatario no pueda recibir el paquete</div>
              <div class="text-s-regular" style="color: #737373">Escoge una opción</div>
            </div>
            <div class="secondAttemptOption_container">
              <SecondAttempCards
                :optionSelected="secondAttemptInPS"
                :isLoading="shippingCostIsLoading"
                :aditionalCostCalculated="aditionalCostCalculated"
                @onUpdateOption="updateSecondAttemptOption"
              />
            </div>
            <div class="text-s-regular">El cobro adicional se realizaría solo en caso de que hubiese un 2do intento de entrega en domicilio, luego de haber coordinado contigo.</div>
          </div> -->

        </div>

        <div class="body_footer">
          <div>
            <ButtonDefinitive
              :isDisabled="shippingCostIsLoading"
              label="Guardar paquete"
              @click="handleAddPackage"
            />
          </div>
          <ShippingCost v-if='!IS_AN_EMBASSY_USER' :isLoading="shippingCostIsLoading" :amount="shippingCost" />
        </div>

      </div>





      <div v-show="false" class="c-popup-slide__header">
          <div class="c-popup-slide__content">
          <h3>Agregar paquete</h3>
          </div>
          <div class="close c-popup-slide__close">
          <img class="c-btn-popup-slide-close" src="~@/assets/img/icon/icon-closex.svg" :data-popup-id="id" />
          </div>
      </div>
      <div v-show="false" class="c-popup-slide__body">
          <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
              <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                  <h4>1) Indicar lugar de entrega</h4>
                  <input-district id="programmed-step-3-modal-pop" text="Buscar distrito"
                  @getDistrictId="setDestinationDistrict" @input="districtId = 0" serviceType="2"
                  @focus="isDestinationFocused = false"></input-district>

                  <div class="c-input input-field curso-pointer">
                  <input ref="destinationAddress" type="text" v-model="destinationAddress.description"
                      @focus="isDestinationFocused = true" max="150" maxlength="150" />

                  <label for>Dirección</label>
                  </div>
                  <div class="c-input input-field mb-2">
                  <input type="text" v-model="addressDetail"
                      placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
                      @focus="isDestinationFocused = false" max="299" maxlength="299" />
                  <label for class="active">Referencia</label>
                  </div>
                  <favorite-address id="favoritePackageAddress" v-show="isDestinationFocused === true"
                  @getSelectedAddress="getDestinationSelectedAddress" modalId="modal-contact-destination" />
              </div>
              <div class="content-package-delivery">
                  <h4>2) Datos de quien recibe</h4>
                  <article v-if="DestinationContact === null" id="btnModalContactFavorite"
                  class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')">
                      <div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                          <div class="card-title color-text-black">
                          <span class="card-sub-title color-text-black">Escoge tu</span><span
                              class="card-option">contacto</span>
                          </div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" />
                      </div>
                  </a>
                  </article>
                  <div v-if="DestinationContact !== null" id="c-contact-send-2"
                  class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                      <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user">
                          <div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                          </div>
                          <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                          </div>
                      </a>
                      </div>
                  </div>
                  </div>
              </div>

              <div class="content-package-tab-result">
                  <h4>3) Tamaño del paquete</h4>
              </div>
              <div class="content-package-information-result">
                  <div class="c-content--box-shadow mb-1">
                  <article class="c-grid--services__item c-section--dateContent">
                      <div>
                      <div class="row m-0">
                          <div class="col s4">
                          <div class="c-input input-field">
                              <input type="text" v-model="packageSize.height" @keypress="isNumber($event)" maxlength="4" />
                              <label for="" class="active">Alto</label>
                          </div>
                          </div>
                          <div class="col s4">
                          <div class="c-input input-field">
                              <input type="text" v-bind:value="packageSize.width" @keypress="isNumber($event)" maxlength="4"
                              v-on:input="
                                  packageSize.width = $event.target.value
                              " />
                              <label for="" class="active">Ancho</label>
                          </div>
                          </div>
                          <div class="col s4">
                          <div class="c-input input-field">
                              <input type="text" v-model="packageSize.lengths" maxlength="4" @keypress="isNumber($event)" />
                              <label for="" class="active">Largo</label>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div class="c-content--range d-flex justify-content-between">
                      <div class="c-modal--directions-body content w-40">
                          <label for="">Peso (kg)</label>
                          <div class="c-input input-field">
                          <input type="number" :min="1" :max="999" v-model="packageSize.weight" />
                          <label for class="font-weight-light active">kg</label>
                          </div>
                      </div>
                      </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                          <input type="text" v-model="description" />
                          <label for="" class="active">Descripción del producto a enviar</label>
                      </div>
                      </div>
                      <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                          <input type="text" v-model="productValue" @keypress="isNumber($event)" />
                          <label for="" class="active">Valor del producto (S/)</label>
                      </div>
                      </div>
                  </article>
                  </div>
                  <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top"
                      data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/1000. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                      <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1" />Tenemos garantía de
                      hasta
                      <span class="font-weight-bold">S/1000 soles</span>
                  </div>
                  <div v-if="amountMax" class="c-section--incognito icongnito-warning">
                      <img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto
                      permitido, de continuar aceptas
                      nuestras restricciones
                  </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                  <div class="m-0 d-flex justify-content-center">
                      <a id="btnOpenModalRestriction"
                      class="c-button c-button--five c-button--mobile-small waves-effect restriccion">
                      Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                  </article>
              </div>
              </div>
          </div>
          </div>
      </div>
      <div v-if="false" class="c-popup-slide__footer">
          <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress()"
              class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar">Guardar</a>
          <button :data-popup-id="id"
              class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">
              Salir
          </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import CustomRadioButton from "../Form/CustomRadioButton.vue";
import InputDistrict from "../General/InputDistrict.vue";
import FavoriteAddress from "../General/FavoriteAddress.vue";
import Map from "../../commons/mixins/maps";
import { getScharffDistrics, getProgrammedFare, getFare, getSizes } from "../../services/fare";
import FormSectionTitle from "../Form/FormSectionTitle.vue";
import DestinationContactButton from "../Button/DestinationContactButton.vue";
import ErrorMessage from "../ErrorMessage.vue";
import InputText from "../Form/InputText.vue";
import InputPackageAttribute from "../Form/InputPackageAttribute.vue";
import CustomSearchSelect from "../Form/CustomSearchSelect.vue";
import CustomCheckbox from "../Form/CustomCheckbox.vue";
import InformativeMessage from "../General/InformativeMessage.vue";
import ButtonDefinitive from "../Button/ButtonDefinitive.vue";
import ShippingCost from "../General/ShippingCost.vue";
import SecondAttempCards from "../Shared/SecondAttempt/SecondAttempCards.vue" 
import PackageSizes from "@/components/Shared/PackageSizes.vue";

function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}


export default {
  components: {
    FormSectionTitle,
    DestinationContactButton,
    CustomRadioButton,
    ErrorMessage,
    InputText,
    InputPackageAttribute,
    CustomSearchSelect,
    CustomCheckbox,
    InformativeMessage,
    ButtonDefinitive,
    ShippingCost,
    SecondAttempCards,
    PackageSizes,

    InputDistrict,
    FavoriteAddress,
  },
  name: "ModalProgrammedPackage",
  props: {
    id: String,
  },
  data() {
    return {
      destinationDistrictId: '',
      destinationPuntoScharff: '',
      address: '',
      reference: '',
      productDescription: '',
      destinationDistrictData: {},
      tryToAddPackage: false,
      isAFragileProduct: false,
      scharffPointsList: [],
      maxLength: null,
      maxWeight: null,
      maxVolumetricWeight: null,
      packageWeight: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageDeep: 0,
      packageValue: 0,
      adicionalInformation: '',
      shippingCostIsLoading: false,
      shippingCost: 0,
      package: {
        hasValueCalculable: false,
        isAddableToList: false
      },
      isShippingPaidAtDestination: false,
      allowSecondAttempt: false,
      secondAttemptInPS: true,
      aditionalCostCalculated: 0,
      sizeList: [],
      sizeSelected: {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      },



      map: null,
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Personalizado",
          title: "Paquete Personalizado",
          description: "Perzonalizado",
          imgUrl: "~@/assets/img/icon/icon-other.svg",
          packageSize: {
            height: 0, // Altura
            lengths: 0, // Largo
            width: 0, // Ancho
            weight: 0, // peso
            maxWeight: 1000,
          },
        },
      ],
      packageSize: {
        height: 0, // Altura
        lengths: 0, // Largo
        width: 0, // Ancho
        type: "Personalizado",
        weight: 0,
        maxWeight: 1000, // Peso
      },
      defaultPackageSize: {
        height: 0, // Altura
        lengths: 0, // Largo
        width: 0, // Ancho
        type: "Personalizado",
        weight: 0,
        maxWeight: 1000, // Peso
      },
      description: "",
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
      },
      districtId: 0,
      addressDetail: "",
    };
  },
  computed: {
    IS_AN_EMBASSY_USER() {
      return this.$store.getters.IS_AN_EMBASSY_USER;
    },
    originDistric() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    exceedsWeightLimit() {
      return this.packageWeight > this.maxWeight
    },
    volumetricWeight() {
      const a = this.packageWidth
      const b = this.packageHeight
      const c = this.packageDeep
      return a * b * c
    },
    exceedsVolumetricWeight() {
      if (this.sizeSelected.codigo !== 'P') return false
      return this.volumetricWeight > this.maxVolumetricWeight
    },
    volumetricWeightMsg() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }); 
      const maxValue = this.maxVolumetricWeight
      const currentValue = this.volumetricWeight
      return `El volumen máximo por paquete en el servicio Puerta Puerta es ${formatter.format(maxValue)} cm³. Has ingresado un volumen de ${formatter.format(currentValue)} cm³.`
    },

    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    },
  },
  watch: {
    productValue() {
      if (this.productValue > 1000) {
        this.productValue = 1000;
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.weight": function () {
      if (this.packageSize.weight > 1000) {
        this.packageSize.weight = "1000";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.lengths": function () {
      if (this.packageSize.lengths > 150) {
        this.packageSize.lengths = "150";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.height": function () {
      if (this.packageSize.height > 190) {
        this.packageSize.height = "190";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.width": function () {
      if (this.packageSize.width > 95) {
        this.packageSize.width = "95";
      }
    },
  },
  created() {
    this.$watch(() => [
      this.destinationDistrictId,
      this.destinationPuntoScharff,
      this.address,
      this.DestinationContact,
      this.productDescription,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
      this.packageValue,
      this.shippingCost,
    ], () => {
      this.checkValues()
    })
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ], debounce(this.checkValuesToCalculate, 300))
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ],
    () => {
      this.shippingCostIsLoading = true
    })
    this.$eventBus.$on("programmedClearDataDestinationModal", () => {
      this.clearData();
    });
    this.$eventBus.$on("programmedSetDataDestinationModal", (destination) => {
      this.setData(destination);
    });
    this.getSizesForProgrammed()
  },
  mounted() {
    const elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems);
    this.map = new Map();
    this.map.initMap("mp_pr_type1");
    // this.getSizesForProgrammed()
  },
  methods: {
    getScharffDistrics,
    getSizes,
    setDestinationDistrictData(districtData) {
      if([67, 128].includes(districtData.provinceId)) { // callao y lima
        this.secondAttemptInPS = this.secondAttemptInPS ?? true
        this.allowSecondAttempt = true
      } else {
        this.allowSecondAttempt = false
        this.secondAttemptInPS = null
      }
      this.destinationDistrictData = districtData
    },
    async checkValues() {
      const {
        destinationDistrictId, address, exceedsVolumetricWeight,
        DestinationContact, productDescription, packageWeight, maxLength, exceedsWeightLimit,
        packageWidth, packageHeight, packageDeep, packageValue, reference
      } = this

      const hasValueCalculable = (
        destinationDistrictId &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsVolumetricWeight
      )

      // this.package.hasValueCalculable = hasValueCalculable
      // if (hasValueCalculable) await this.calculateCost()
      // else this.shippingCost = 0

      this.package.isAddableToList = (
        hasValueCalculable &&
        packageValue &&
        address.length > 0 && address.length <= 150 &&
        reference.length <= 150 &&
        this.shippingCost > 0 &&
        DestinationContact &&
        productDescription &&
        !this.shippingCostIsLoading
      )
      console.log('hasValueCalculable: ', hasValueCalculable)
      console.log('packageValue: ', packageValue)
      console.log('address.length: ', address.length)
      console.log('reference.length: ', reference.length)
      console.log('this.shippingCost: ', this.shippingCost)
      console.log('DestinationContact: ', DestinationContact)
      console.log('productDescription: ', productDescription)
      console.log('this.shippingCostIsLoading: ', this.shippingCostIsLoading)
      console.log('this.package.isAddableToList: ', this.package.isAddableToList)

    },
    async checkValuesToCalculate() {
      const {
        destinationDistrictId, packageWeight, packageWidth, packageHeight, packageDeep,
        exceedsVolumetricWeight, exceedsWeightLimit, maxLength
      } = this

      const hasValueCalculable = (
        destinationDistrictId &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsVolumetricWeight
      )

      this.package.hasValueCalculable = hasValueCalculable
      if (hasValueCalculable) await this.calculateCost()
      else this.shippingCost = 0
      this.shippingCostIsLoading = false
    },
    async calculateCost() {
      this.shippingCostIsLoading = true
      try {
        const fare = await getFare({
          "fareItems": [
            {
              "originDistrictId": this.originDistric.districtId.id,
              "destinationDistrictId": this.destinationDistrictId,
              "packageWidth": this.packageWidth,
              "packageHeight": this.packageHeight,
              "packageWeight": this.packageWeight,
              "packageDeep": this.packageDeep,
              "serviceType": 2,
              "sizeId": this.sizeSelected.tallaId,
              "shippingTypeId": 4 // Programados
            }
          ]
        })
        this.shippingCost = fare.data.totalFare
        this.aditionalCostCalculated = fare.data.secondTryFare.toFixed(2) || 0
      } catch (e) {
        this.$swal({
          title: "Error!",
          text: e,
          icon: "error",
        })
      } finally {
        this.shippingCostIsLoading = false
      }
    },
    handleAddPackage() {
      this.trackEvent('click_programado_paso_3_guardar_paquete', 'programado')
      try {
      this.tryToAddPackage = true
      if(!this.package.isAddableToList) {
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: "warning",
          confirmButtonText: "Aceptar"
        })
        return
      }

      const allPackageData = {
        index: this.$store.getters.PROGRAMMED_INDEX_DESTINATION,
        item: {
          description: this.productDescription,
          destinationAddress: {
            additionalAddress: this.reference,
            contact: this.DestinationContact,
            description: this.address,
            districtId: {
              id: this.destinationDistrictData.id,
              text: this.destinationDistrictData.text,
              textToFinded: this.destinationDistrictData.textToFind,
            },
            lat: null,
            lng: null,
            puntoScharffId: this.destinationPuntoScharff
          },
          discountFare: null,
          fare: this.shippingCost,
          secondAttemptInPS: this.secondAttemptInPS,
          isPointPoint: null,
          packageSize: {
            height: this.packageHeight,
            lengths: this.packageDeep,
            maxWeight: null,
            type: null, // lo que era "pequeño", "mediano", etc
            weight: this.packageWeight,
            width: this.packageWidth
          },
          fragil: this.isAFragileProduct,
          productValue: this.packageValue,
          sizeSelected: this.sizeSelected,
          shippingTypeId: 4 // Programados
        },
      }
      this.$store.dispatch("PROGRAMMED_ORDER_SET_DESTINATION", allPackageData);
      const popup = document.getElementById(this.id)
      popup.classList.remove("is-active");
    } catch (e) {
        console.log({e})
      }
    },
    updateSecondAttemptOption(option) {
      this.secondAttemptInPS = option
    },
    async getSizesForProgrammed(type) {
      const sizes = await this.getSizes(type)
      const personalizedSize = sizes.find(size => size.codigo === 'P')
      this.maxLength = personalizedSize.alto
      this.maxWeight = personalizedSize.pesoMaximo
      this.maxVolumetricWeight = personalizedSize.pesoMaximo * 5000
      this.sizeList = sizes
    },
    handleSizeSelected(size) {
      this.sizeSelected = size
      if (size.codigo === 'P') {
        this.packageWeight = 0
        this.packageWidth = 0
        this.packageHeight = 0
        this.packageDeep = 0
      } else {
        this.packageWeight = size.pesoMaximo
        this.packageWidth = size.ancho
        this.packageHeight = size.alto
        this.packageDeep = size.profundidad
      }
    },


    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (
        this.packageSize.weight === null ||
        this.packageSize.weight === 0 ||
        this.packageSize.weight === "0"
      ) {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }
      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(
          `El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight} kg`
        );
        return false;
      }
      if (this.packageSize.type === "Personalizado") {
        if (
          parseInt(this.packageSize.height, 10) === 0 ||
          this.packageSize.height === ""
        ) {
          this.showError(`Debe ingresar la altura del paquete`);
          return false;
        }
        if (
          parseInt(this.packageSize.width, 10) === 0 ||
          this.packageSize.width === ""
        ) {
          this.showError(`Debe ingresar el ancho del paquete`);
          return false;
        }
        if (
          parseInt(this.packageSize.lengths, 10) === 0 ||
          this.packageSize.lengths === ""
        ) {
          this.showError(`Debe ingresar el largo del paquete`);
          return false;
        }
      }
      if (this.description === null || this.description === "") {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (this.destinationAddress.description === "") {
        this.showError("Debe de ingresar la direccion del paquete");
        return false;
      }
      if (this.districtId === 0) {
        this.showError(
          "Debe seleccionar un distrito de las opciones desplegables"
        );
        return false;
      }

      if (this.DestinationContact === null) {
        this.showError("Debe seleccionar un contacto");
        return false;
      }
      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
      };
      return fareRequest;
    },
    saveDestinationAddress() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.PROGRAMMED_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              description: this.destinationAddress.description,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              contact: this.DestinationContact,
              addressDetail: this.addressDetail,
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            discountFare: null,
          },
        };

        const obj = this.setModel(this.origin, destinationTemp.item);

        this.showLoading();


        const responseFare = getProgrammedFare(obj)
          .then((response) => {
            debugger;
            destinationTemp.item.fare = response.data.totalFare;
            const destination = JSON.parse(JSON.stringify(destinationTemp));

            this.$store.dispatch(
              "PROGRAMMED_ORDER_SET_DESTINATION",
              destination
            );

            const sumall = this.lstDestination
              .map((item) => item.fare)
              .reduce((prev, curr) => prev + curr, 0);
            this.$store.dispatch("PROGRAMMED_FARE", sumall);
            this.$swal.close();
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
        // this.$store
        //   .dispatch("PROGRAMMED_CALCULATE_FARE", obj)
        //   .then((response) => {
        //     destinationTemp.item.fare = response.totalFare;
        //     const destination = JSON.parse(JSON.stringify(destinationTemp));

        //     this.$store.dispatch(
        //       "PROGRAMMED_ORDER_SET_DESTINATION",
        //       destination
        //     );

        //     const sumall = this.lstDestination
        //       .map((item) => item.fare)
        //       .reduce((prev, curr) => prev + curr, 0);
        //     this.$store.dispatch("PROGRAMMED_FARE", sumall);
        //     this.$swal.close();
        //   })
        //   .catch((error) => {
        //     this.$swal({
        //       title: "Error!",
        //       text: error,
        //       icon: "error",
        //       confirmButtonText: "Aceptar",
        //     });
        //   });

        const btnPopupSlideClose = document.querySelectorAll(".boton-guardar");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        if (this.packageSize.weight > item.packageSize.maxWeight) {
          this.packageSize.weight = item.packageSize.maxWeight;
        }
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit(
          "updateAddressInput",
          this.destinationAddress.description
        );
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact-destination");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    setDestinationDistrict(id) {
      this.districtId = id;
    },

    setAddressByGeo(event) {
      const pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      const $this = this;
      this.map.geocodeLocation(pos, (result) => {
        $this.destinationAddress.description = result.formatted;
        $this.destinationAddress.lat = result.position.lat;
        $this.destinationAddress.lng = result.position.lng;
        $this.destinationAddress.favorite = false;
        $this.$refs.destinationAddress.update(result.formatted);
        $this.$swal.close();
      });
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(
        { Lat: addressData.latitude, Lng: addressData.longitude },
        null,
        false,
        draggedHandler
      );
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(
        favoriteLocation.location,
        null,
        false,
        draggedHandler
      );
      // this.saveDestinationAddress();
    },
    clearData() {
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
      this.destinationDistrictId = ''
      this.address = ''
      this.reference = ''
      this.productDescription = ''
      this.isAFragileProduct = false
      this.packageWeight = 0
      this.packageWidth = 0
      this.packageHeight = 0
      this.packageDeep = 0
      this.packageValue = 0
      this.secondAttemptInPS = true
      this.adicionalInformation = ''
      this.package = {
        hasValueCalculable: false,
        isAddableToList: false
      }
      this.shippingCostIsLoading = false
      this.shippingCost = 0
      this.tryToAddPackage = false
      this.destinationDistrictData = {}
      this.sizeSelected = {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      }
    },
    setData(destination) {
      try {
        this.$refs.CustomSelectComponentRef.selectElementFromOutside(destination.destinationAddress.districtId)
        this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destination.destinationAddress.contact);
        this.address = destination.destinationAddress.description
        this.reference = destination.destinationAddress.additionalAddress
        this.productDescription = destination.description
        this.packageWeight = destination.packageSize.weight
        this.packageWidth = destination.packageSize.width
        this.packageHeight = destination.packageSize.height
        this.packageDeep = destination.packageSize.lengths
        this.packageValue = destination.productValue
        this.secondAttemptInPS = destination.secondAttemptInPS
        this.isAFragileProduct = destination.fragil
        this.package = {
          hasValueCalculable: true,
          isAddableToList: true
        }
        this.shippingCostIsLoading = false
        this.shippingCost = destination.fare
        this.tryToAddPackage = false
        this.sizeSelected = destination.sizeSelected
      } catch (e) {
        console.log(e)
      }
    },
  },
};
</script>

<style>
@import '../../styles/09-componentsv2/addPackage.scss';

/* .secondAttemptOption_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 8px;
  padding: 24px 0;
} */

/* .secondAttemptOption_body {
  display: flex;
  flex-direction: column;
} */
</style>
