<template>
  <!-- <div
    v-if="lstDestination.length === 0"
    class="c-nav c-nav--floating-footer-sticky"
  >
    <a
      class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      @click.prevent="next"
      id="btnOpenModalSetAdress"
      >Continuar</a
    >
  </div> -->
  <ButtonDefinitive
    v-if="lstDestination.length === 0"
    label="Continuar"
    @click="next"
  />
  <div v-else class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
    <ButtonDefinitive
      label="Continuar"
      @click="next"
    />
    <ButtonDefinitive
      variant='tertiary'
      label="Agregar"
      @click="addDestinationAddress()"
    >
      <template v-slot:icon>
        <div style='padding-left: -20px;'>
          <Icon name='Plus4' />
        </div>
      </template>
    </ButtonDefinitive>
  </div>
</template>

<script>
import { readFileProgrammed } from "../../../services/massiveLoad";
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: "Step3-Footer",
  mounted() {
    this.$nextTick(() => {});
  },
  components: { ButtonDefinitive, Icon },
  methods: {
    addDestinationAddress() {
      this.$emit("getDefaultContact");
      this.$store.dispatch(
        "PROGRAMMED_ORDER_SET_INDEX_DESTINATION",
        this.lstDestination.length
      );
      this.$eventBus.$emit("programmedClearDataDestinationModal");
      const modalpackage = document.querySelector("#modal-package");
      modalpackage.classList.add("is-active");
      document.getElementById("programmed-step-3-modal-pop").value = "";

      this.trackEvent('click_programado_agregar_otro_paquete', 'programado');
    },
    async calculateFare(data) {
      let fareResponse = null;
      const fareRequest = {
        PackageValue: data.productValue,
        PackageWidth: data.packageSize.width,
        PackageHeight: data.packageSize.height,
        PackageDeep: data.packageSize.lengths,
        PackageWeight: data.packageSize.weight,
        PackageType: data.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: true,
        isServiceScharffPoint: false,
        OriginDistrictId: this.origin.districtId.id,
        DestinationDistrictId: data.destinationAddress.districtId.id,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
      };

      await this.$store
        .dispatch("POST_CALCULATE_FARE", fareRequest)
        .then((success) => {
          if (success.status === 200) {
            fareResponse = success.data.result;
          } else {
            this.showError(success.data.message);
            fareResponse = null;
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.showError(catchMsg);
          fareResponse = null;
        });

      return fareResponse;
    },
    async getDepProDisByText(department, province, district) {
      const payload = {
        departamento: department,
        provincia: province,
        distrito: district,
      };

      let res = null;
      await this.$store
        .dispatch("GET_EXCEL_DEP_PRO_DIS", payload)
        .then((success) => {
          if (success.status === 200) {
            res = success.data.result;
          } else {
            this.showError(success.data.message);
            res = null;
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
          res = null;
        });

      return res;
    },
    async processExcelData(destinations) {
      let error = false;
      let totalSumFare = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of destinations) {
        // eslint-disable-next-line no-await-in-loop
        const res = await this.getDepProDisByText(
          item.department,
          item.province,
          item.district
        );
        if (!res) {
          error = true;
          break;
        }
        const data = {
          description: item.description, // del paquete
          destinationAddress: {
            additionalAddress: item.reference,
            contact: {
              contactId: null,
              documentNumber: item.dni,
              email: item.email,
              name: item.name,
              phone: item.cellphone,
            },
            description: item.address, // direccion
            districtId: {
              id: res.districtId,
              text: `${res.departmentText} - ${res.provinceText} - ${res.districtText}`, // dep - pro - dis
            },
            lat: null,
            lng: null,
          },
          fare: null,
          packageSize: {
            height: item.height,
            lengths: item.deep,
            type: "Personalizado",
            weight: item.weight,
            width: item.width,
          },
          productValue: item.value,
        };
        // eslint-disable-next-line no-await-in-loop
        const fare = await this.calculateFare(data);
        if (!fare) {
          error = true;
          break;
        }

        data.fare = fare.totalFare;
        totalSumFare += fare.totalFare;

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch("PROGRAMMED_APPEND_DESTINATION", data);
      }
      this.$store.dispatch("PROGRAMMED_FARE", totalSumFare);
      if (!error) {
        this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 4);
        this.$swal.close();
      }
    },
    async next() {
      this.showLoading("Estamos cargando tu archivo.");
      if (this.lstDestination.length <= 0) {
        if (this.file) {
          this.$store.dispatch("PROGRAMMED_CLEAR_DESTINATION");
          let valid = false;
          let destinations = null;
          const formData = new FormData();
          this.file.forEach((f) => {
            formData.append("file", f);
            formData.append("originDistrict", this.origin.districtId.id);
          });
          const response = await readFileProgrammed(formData);
          if (response.status === 200) {
            valid = true;
            destinations = response.data;
            this.trackEvent('click_programado_paso_3_plantilla_procesada', 'programado')
          } else {
            this.showError(response.data.message);
            this.trackEvent('click_programado_paso_3_plantilla_procesada_error', 'programado')
          }

          if (valid === true) {
            await this.$store.dispatch('PROGRAMMED_APPEND_DESTINATION_FROM_EXCEL', destinations)
            // const sumAll = this.lstDestination
            //   .map((item) => item.fare)
            //   .reduce((prev, curr) => prev + curr, 0)
            // this.$store.dispatch("PROGRAMMED_FARE", sumAll)
            this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 4)
            this.$swal.close();
            // await this.processExcelData(destinations);
          }
        } else {
          this.showError("Se debe ingresar por lo menos un producto");
        }
      } else if (this.validateData()) {
        const sumall = this.lstDestination
          .map((item) => item.fare)
          .reduce((prev, curr) => prev + curr, 0);
        this.$store.dispatch("PROGRAMMED_FARE", sumall);
        this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 4);
        this.$swal.close();

        this.trackEvent('click_programado_paso_3_continuar', 'programado')
      } else {
        this.showError("Agrega un paquete como mínimo.");
      }
    },
    validateData() {
      const list = this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
      if (list.length === 0) {
        return false;
      }
      return true;
    },
  },
  computed: {
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    file() {
      return this.$store.getters.PROGRAMMED_FILE;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
  },
};
</script>

<style></style>
