<template>
  <div>
    <div class="flat_rate_section">
      <div class="flat_rate_section_container">
        <div class="titles">
          <div class="flat_rate_title">Cálculo de Tarifas</div>
          <div class="flat_rate_subtitle">Precios personalizados según tu envío</div>
        </div>
        <!-- <div class="flat_rate_price">{{ cost }}</div> -->
        <span class="section_footer">* El monto especificado en la página web corresponde a las medidas y pesos declarados que serán verificados al llegar a nuestras instalaciones. De haber una diferencia en las medidas y/o peso, los paquetes se mantendrán en espera de la regularización de la tarifa o de la devolución a origen.</span>
      </div>
    </div>
    <div class="footer_button">
      <div class="" style="font-size: 24px; font-weight: 700; text-align: center;">¿Qué esperas para realizar envíos?</div>
      <div class=''>
        <ButtonDefinitive label="Empezar a enviar" @click="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
import BlockWithButton from "@/views/components/general/app-block-with-button.vue";
import ButtonDefinitive from "../../../components/Button/ButtonDefinitive.vue";

export default {
  components: {
    BlockWithButton,
    ButtonDefinitive,
  },
  props: {
    cost: {
      type: String,
      default: 'S/ 0.00'
    },
    route: {
      type: String,
      default: ''
    },
  },
  methods: {
    closeModal() {
      // this.$router.push('/puntoscharff')
      this.$router.push(`/${this.route}`)
      const modal = document.querySelector("#modalCalculatorScheduledDelivery")
      const instance = M.Modal.getInstance(modal)
      instance.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.text__consult_areas {
  padding: 30px 0;
}
.flat_rate_section {
  padding: 64px 0;
  margin: 30px 0;
  background-color: #FFEFEF;
  color: #1A1A1A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flat_rate_section_container {
  width: 75%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  // gap: 20px;
  row-gap: 32px;
  column-gap: 10px;
}
.titles {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  color: #68002B;
}
.flat_rate_title {
  font-size: 24px;
  font-weight: 700;
}
.flat_rate_subtitle {
  font-size: 16px;
  font-weight: 400;
}
.flat_rate_price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  justify-self: end;

  width: 130px;
  height: 60px;

  background: #FFFFFF;
  // box-shadow: 0px 8px 16px rgba(26, 26, 26, 0.08);
  border-radius: 8px;

  font-weight: 700;
  font-size: 20px;
  color: #68002B;
}
.section_footer {
  grid-column-start: span 2;
  font-weight: 400;
  font-size: 12px;
  color: #707070;
}
.footer_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px;
  gap: 32px;
  justify-content: center;
  height: 272px;
}

@media (max-width: 520px) {
  .titles {
    align-items: center;
  }
  .flat_rate_section_container {
    grid-template-columns: 1fr;
  }
  .section_footer {
    text-align: center;
    grid-column-start: span 1;
  }
  .flat_rate_price {
    justify-self: center;
  }
}

</style>