<template>
  <article ref='container_history_v2' class='c-content--sendPoint c-scrollbar container_history_v2'>
    <div class='c-grid c-section--content-favorite c-m-top-for-nav-md'>
      <div class='c-section--content-article'>
        <article class='c-grid--services__item m-1'>
          <div class='c-section--content-date'>
            <div class='date-info disabled-shadow'>
              <div class='date-info-content-box p-0'>
                <div class='info-black'>
                  Resultado de búsqueda <br />
                  <span class='info-light'>
                    {{ pageInfo }}
                  </span>
                </div>
                <div class='info-light'>
                  <a href='javascript:void(0)' id='btnOpenModalSearch'>
                    <img src='~@/assets/img/icon/icon-filtro.svg' alt='' width='25' height='auto' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>

    <history-item
      v-for='(order, index) in orders'
      :key='order.index'
      :order='order'
      :index='index'
    />

    <div style='display: flex; justify-content: space-between; align-items: center; margin: 0 10px;'>
      <ButtonDefinitive variant='ghost' size='s' label='Anterior' :isDisabled='!pagination.hasPrevPage' @click='navPage(pagination.page - 1)' />
      <div style='width: 100%; text-align: center;'>Pagina {{ pagination.page }} de {{ pagination.totalPages }}</div>
      <ButtonDefinitive variant='ghost' size='s' label='Siguiente' :isDisabled='!pagination.hasNextPage' @click='navPage(pagination.page + 1)' />
    </div>

    <modal-search />
  </article>
</template>

<script>
import ModalSearch from '../Modals/ModalSearch.vue';
import HistoryItem from '../General/HistoryItem.vue';
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: 'HistoryCmp',
  components: {
    ModalSearch,
    HistoryItem,
    ButtonDefinitive,
  },
  mounted() {
    this.setInitialConfig()
  },

  methods: {
    setInitialConfig() {
      this.$store.dispatch('UPDATE_PAGINATION_DETAILS', { page: 1, pageSize: 10 })
      this.getOrders()
    },
    getOrders() {
      this.showLoading('Cargando...')
      this.$store.dispatch('HISTORY_FETCH_ORDERS')
        .then(() => {
          this.$swal.close()
        })
        .catch(error => {
          this.showError(error)
        })
    },
    navPage(newPage) {
      this.$store.dispatch('UPDATE_PAGINATION_DETAILS', { page: newPage })
      this.getOrders()
    }
  },
  computed: {
    orders() {
      return this.$store.getters.HISTORY_ORDERS
    },
    pagination() {
      const historyPagination = this.$store.getters.HISTORY_PAGINATION

      if (historyPagination.totalCount > 0 ) { // solo para hacer scroll
        this.$refs.container_history_v2.scrollTo({ top: 0, behavior: 'smooth' })
      }
      return historyPagination
    },
    pageInfo() {
      const {
        page, pageSize, hasNextPage, totalCount
      } = this.pagination

      const start = (page - 1) * pageSize + 1
      const end = start + (hasNextPage ? pageSize : this.orders.length) - 1

      return `Mostrando ${start}-${end} de ${totalCount}`
    }
  },
}
</script>
<style>
@media screen and (min-width: 780px) {
  .container_history_v2 {
    padding-top: 60px !important;
  }
}
</style>
