<!-- eslint-disable max-len -->
<template>
  <div :id="id" class="c-modal modal" tabindex="0" style="z-index: 5000">
    <img
        @click="closePopup"
        class="modal-close"
        src="~@/assets/img/icon/icon-closex-bg_white.png"
        style="
          width:64px;
          padding:12px;
          border-radius: 25px;
          position: absolute;
          top: 8px;
          right: 8px;
          transition: background 400ms;
          cursor: pointer;
          z-index: 9;
        "
      />
    <img
      @click="openPdf"
      class="hide-on-mobile cursor-pointer"
      src="~@/assets/img/popop-new_prices.png"
      alt=""
      style="border-radius: 16px; width: 100%; object-fit: cover; object-position: center"
    />
    
  </div>
</template>
<script>
export default {
  name: "ModalScharffRealPlaza",
  props: {
    id: String,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  async mounted() {
    const viewedCount = Number(
      localStorage.getItem("popUp_prices_by_size_2") || 0
    );
    const rutaActual = this.$route.path;
    if (viewedCount < 3 && rutaActual === '/') {
      this.showPopup = true;
      await this.$nextTick();
      this.openModalDismissible("modalScharffRealPlaza");
      localStorage.setItem("popUp_prices_by_size_2", viewedCount + 1);
    }
  },
  methods: {
    closePopup() {
      const modalOnboardingAppStart = document.getElementById(this.id);
      modalOnboardingAppStart.style.display = "none";
    },
    openPdf() {
      const pdfUrl = "https://drive.google.com/file/d/1LZAmZ4Z26My1t2ISF_Q8d32I-gRrVjk5/view";
      window.open(
        pdfUrl,
        "popupWindow",
        "width=800,height=600,scrollbars=yes,resizable=yes"
      );
    },
  },
};
</script>
<style scoped>

#modalScharffRealPlaza{
  background-color: #FFF;
  width: 600px;
}

.modal-close:hover{
  background-color: #1A1A1A10;
}

.modal-close:active{
  background-color: #1A1A1A20;
}

.c-modal {
  border-radius: 16px;
  background-color: #FFF !important;
  line-height: 0;
}

@media screen and (max-width: 1600px) {
  .c-modal {
    width: 500px !important;
    top: 0px !important;
  }
}
@media screen and (max-width: 868px) {
  .c-modal {
    width: 400px !important;
    top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .c-modal {
    width: 400px !important;
    top: 0px !important;
  }
}

@media screen and (max-width: 440px) {
  .c-modal {
    /* max-width: 45% !important; */
    width: auto !important;
    top: 0px !important;
    margin-left: 15px;
    margin-right: 15px;
  }
}
</style>
