export default {
  state: {
    options: {
      show: false,
      type: "",
      cards: null,
    },
  },
  mutations: {
    SET_SERVICE_INFO: (state, value) => {
      state.options = value;
    },
  },
  getters: {
    SERVICE_INFO: (state) => state.options,
  },
  actions: {
    SET_SHOW_SERVICE_DESCRIPTION: ({ commit }, type) => {
      let options = null;
      switch (type) {
        case "express":
          options = {
            show: true,
            title: "Express",
            route: "/express",
            cards: [
              {
                title: "¿Cómo funciona?",
                text: "Recogemos tus envíos y los entregamos en la dirección que elijas con nuestros motorizados.",
              },
              {
                title: "Cobertura",
                text: "Llegamos a varios distritos de Lima Metropolitana.",
              },
              {
                title: "Tiempos de entrega",
                text: "Entregas en menos de 60 minutos",
              },
              {
                title: "¿Qué puedes enviar?",
                text: "Todo lo que entra en nuestra mochila de 40x40x40cm.",
                extra: "*Excepción comida y bebidas",
              },
              {
                title: "Seguro",
                text: "Tus envios están asegurados hasta un monto de S/ 1,000.",
              },
              {
                title: "Tarifas",
                columns: [
                  {
                    text: "S/8,16",
                    description: "Base",
                  },
                  {
                    text: "S/1",
                    description: "KM adicional",
                  },
                ],
              },
            ],
          };
          break;

        case "programmed":
          options = {
            show: true,
            title: "Puerta a puerta",
            route: "/programmed",
            cards: [
              {
                title: "¿Cómo funciona?",
                text: "Recogemos tus envíos y los entregamos en la dirección que elijas.",
              },
              {
                title: "Cobertura",
                text: "Llegamos a toda Lima y a muchas provincias de todo el Perú.",
              },
              {
                title: "Tiempos de entrega",
                text: "Lima Metropolitana de 24 a 48 hrs. Provincias de 2 a 5 días hábiles dependiendo del destino.",
              },
              {
                title: "Carga Masiva",
                text: "Puedes registrar hasta 100 paquetes con diferentes destinos al mismo tiempo.",
              },
              {
                title: "Seguro",
                text: "Tus envios están asegurados hasta un monto de S/ 1,000.",
              }
            ],
          };
          break;

        case "puntos":
          options = {
            show: true,
            title: "Punto Sharf",
            route: "/puntoscharff",
            cards: [
              {
                title: "¿Qué son?",
                text: "Son negocios (bodegas, locutorios, farmacias) en donde puedes enviar o recoger tus productos.",
              },
              {
                title: "Cobertura",
                text: "Llegamos a todo Lima y a muchas provincias al rededor del Perú.",
              },
              {
                title: "Tiempos de entrega",
                text: "Lima Metropolitana de 24 a 48 hrs. Provincias de 2 a 5 días hábiles dependiendo del destino.",
              },
              {
                title: "¿Cómo funciona?",
                isHtml: true,
                text: "<span>Tenemos 3 modalidades.</span><br/><ul><li>Punto a Domicilio</li><li>Punto a  Punto</li><li>Domicilio a Punto</li></ul>",
              },
              {
                title: "Seguro",
                text: "Tus envios están asegurados hasta un monto de S/ 700.",
              }
            ],
          };
          break;

        case "cyt":
          options = {
            show: true,
            title: "Compra y Trae",
            route: "/compraytrae",
            cards: [
              {
                title: "¿Cómo funciona?",
                text: "Envía tus compras online a la dirección de nuestro almacén en Miami y las traemos a la puerta de tu casa.",
              },
              {
                title: "Cobertura",
                text: "Entregamos tus compras online en todo el Perú.",
              },
              {
                title: "Tiempos de entrega",
                text: "Entrega en 15 días desde que recibimos tu paquete en Miami.",
              },
              {
                title: "Compras consolidadas",
                text: "Puedes traer hasta 4 compras de distintas tiendas juntas.",
              },
              {
                title: "Libre de aranceles",
                text: "En pedidos de hasta $200.",
              },
              {
                title: "Tarifas",
                text: "Conócelas",
                buttonText: "Cotiza online",
                buttonAction: "btnShowModalCalculatorBuyAndBring",
              },
            ],
          };
          break;

        default:
          break;
      }
      commit("SET_SERVICE_INFO", options);
    },
  },
};
