<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container add_package_slide px-0">
      <!-- @start Header-->
      <div class="header_add_packagev2">
        <div class="c-btn-popup-slide-close back_button" :data-popup-id="id">
          <svg width="32" height="32" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000">
            <path d="m15 6-6 6 6 6" stroke="#000" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="header_title">Agregar paquete</div>
      </div>
      <!-- @start content-->
      <div class="body_add_packagev2">
        <div class="sections_container">

          <!-- Método de pago -->
          <div class="title_section-and-section ">
            <FormSectionTitle title="Método de pago" />
            <div style="margin: -12px -12px 0 -12px;">
              <CustomRadioButton
                radioId="opcion1"
                :inputValue="false"
                v-model="isShippingPaidAtDestination"
                label="Pago en línea"
              />
              <CustomRadioButton
                radioId="opcion2"
                :inputValue="true"
                v-model="isShippingPaidAtDestination"
                label="Pago al recoger el paquete"
              />
            </div>
          </div>

          <!-- Lugar de entrega -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Lugar de entrega" />

            <div class="delivery-place-inputs">
              <CustomSearchSelect
                v-if='!isShippingPaidAtDestination'
                @setSelectedDisctrict="setDestinationDistrictData"
                ref="CustomSelectComponentRef"
                v-model="destinationDistrictId"
                :repoSearch="getScharffDistrics"
                label="Distrito"
                placeholder="Busca por departamento, provincia o distrito"
                errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="tryToAddPackage && !destinationDistrictId"
              />
              <ErrorMessage v-if="!hasLimaOrCallaoDistrict" style="margin-top: 3px" message="Por el momento solo realizamos envíos de Lima Metroplitana a provincia y de provincia a Lima Metropolitana. Intenta ingresar nuevamente un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."/>
              <CustomSelect
                isForScharffPoints
                label="Punto Sharf"
                placeholder="Selecciona un Punto Sharf"
                :optionList="scharffPointsList"
                v-model="destinationPuntoScharff"
                errorMessage="Selecciona un Punto Sharf."
                :showEmptyInputError="tryToAddPackage && !destinationPuntoScharff"
                @change="setMarketV2"
              />
              <div class="c-popup-slide__map" id="mp_ps_type2"></div>
            </div>
          </div>

          <!-- Datos de quien recibe -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Datos de quien recibe" />
            <div class="delivery-place-inputs">
              <DestinationContactButton
                @click="openContactModal('destination'), trackEvent('click_puntos_pickup_paso_3_contacto_btn', 'pickup')"
                :destinationContact="DestinationContact"
                missingErrorMessage="Escoge un contacto."
              />
              <div v-if="tryToAddPackage && !DestinationContact" style="margin-top: -15px;">
                <ErrorMessage
                  message="Escoge un contacto."
                />
              </div>
            </div>
          </div>

          <!-- Características del paquete -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Características del paquete" />
            <div class="delivery-place-inputs">
              <InputText
                label="Descripción del producto"
                :maxLength="150"
                placeholder="¿Qué es lo que envías?"
                v-model="productDescription"
                missingErrorMessage="Ingresa la descripción del producto."
                :showEmptyInputError="tryToAddPackage && !productDescription"
              />
              <CustomCheckbox
                checkboxId="fragile-product"
                v-model="isAFragileProduct"
                label="Producto frágil"
              />
              <div class="package_measurements">
                <PackageSizes :sizeList='sizeList' :sizeSelectedId='sizeSelected.tallaId' @onSizeSelected='handleSizeSelected' />
                <div v-if="sizeSelected.codigo === 'P'" class="attributes">
                  <InputPackageAttribute
                    label="Peso"
                    measure="kg"
                    v-model="packageWeight"
                    missingErrorMessage="Ingresa el Peso."
                    :showEmptyInputError="tryToAddPackage && !packageWeight"
                  />
                </div>
                <ErrorMessage v-show="exceedsWeightLimit" :message="`El peso máximo permitido por paquete en el servicio Puntos Sharf es ${maxWeight} kg.`" />
                <div v-if="sizeSelected.codigo === 'P'" style="padding-top: 16px;" class="attributes">
                  <InputPackageAttribute
                    label="Largo"
                    measure="cm"
                    v-model="packageWidth"
                    missingErrorMessage="Ingresa el Largo."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageWidth"
                  />
                  <InputPackageAttribute
                    label="Alto"
                    measure="cm"
                    v-model="packageHeight"
                    missingErrorMessage="Ingresa el Alto."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageHeight"
                  />
                  <InputPackageAttribute
                    label="Ancho"
                    measure="cm"
                    v-model="packageDeep"
                    missingErrorMessage="Ingresa el Ancho."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageDeep"
                  />
                </div>
                <ErrorMessage
                  v-if="
                    packageWidth <= maxLength &&
                    packageHeight <= maxLength &&
                    packageDeep <= maxLength
                  "
                  :message="volumetricWeightMsg"
                  v-show="exceedsVolumetricWeight"
                />
                <div class="attributes" style="padding-top: 16px">
                  <InputPackageAttribute
                    label="Valor del producto"
                    measure="S/"
                    v-model="packageValue"
                    missingErrorMessage="Ingresa el valor del producto."
                    :showEmptyInputError="tryToAddPackage && !packageValue"
                    isCurrency
                  />
                </div>
              </div>
              <div style="padding-top: 8px">
                <InformativeMessage message="Tenemos garantía de hasta S/ 700." />
              </div>
            </div>
          </div>

          <!-- Información adicional (opcional) -->
          <!-- <div class="title_section-and-section">
            <FormSectionTitle title="Información adicional (opcional)" />
            <InputText
              isTextArea
              placeholder="¿Deseas agregar alguna indicación para tu envío?"
              v-model="adicionalInformation"
            />
          </div> -->

        </div>

        <div class="body_footer">
          <div>
            <ButtonDefinitive
              :isDisabled="shippingCostIsLoading"
              label="Guardar paquete"
              @click="handleAddPackage"
            />
          </div>
          <ShippingCost :isLoading="shippingCostIsLoading" :amount="shippingCost" />
        </div>

      </div>






      <div v-show="false" class="c-popup-slide__header">
        <div class="c-popup-slide__content">
          <h3>Agregar paquete</h3>
        </div>
        <div class="close c-popup-slide__close">
          <img class="c-btn-popup-slide-close" src="~@/assets/img/icon/icon-closex.svg" :data-popup-id="id" />
        </div>
      </div>
      <div v-show="false" class="c-popup-slide__body">
        <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
            <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                <h4>1) Indicar Punto Sharf</h4>
                <input-district id="scharffpoint-step-3-type-2-modal-pop-district" text="Buscar distrito" @getDistrictId="setDestinationDistrict2" serviceType="2" @input="districtId = 0"></input-district>
                <div class="c-select input-field curso-pointer">
                  <select id="scharff-point" v-model="puntoScharffId" @change="setMarket()">
                    <option v-for="item in lstScharffPoint" v-bind:value="item" v-bind:key="item.ScharffPointId">
                      {{ item.Name }}
                    </option>
                  </select>
                  <label for="" class="select-label">Punto Sharf</label>
                </div>
                <div class="c-input input-field mb-2">
                  <textarea v-model="destinationAddress.additionalAddress" maxlength="299" id="txtareaReference" data-length="120" class="materialize-textarea" placeholder="Escriba aquí si desea agregar alguna indicación especial para su pedido"></textarea>
                  <label for="txtareaReference" class="">Información adicional</label>
                </div>
                <!-- <div class="c-popup-slide__map" id="mp_ps_type2"></div> -->
              </div>
              <div class="content-package-delivery">
                <h4>2) Datos de quien recibe</h4>
                <article v-if="DestinationContact === null" id="btnModalContactFavorite" class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')"
                    ><div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                        <div class="card-title color-text-black"><span class="card-sub-title color-text-black">Escoge tu</span><span class="card-option">contacto</span></div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" /></div
                  ></a>
                </article>
                <div v-if="DestinationContact !== null" id="c-contact-send-2" class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                    <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user"
                        ><div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                        </div>
                        <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                        </div></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-package-tab-result">
                <h4>3) Tamaño del paquete</h4>
                <article class="c-grid--services__item">
                  <div class="c-section--content-package">
                    <ul id="navList" class="navList c-nav--tab-d-c d-flex justify-content-between m-0 w-100 tabs h-100 h-100 c-bg-none">
                      <li v-for="item in listPackageSize" v-bind:key="item.id" class="c-tab-button item-package tab h-100 line-height-initial">
                        <a
                          :href="'#' + item.id"
                          v-on:click="setTypePackageSize(item)"
                          v-bind:class="{
                            active: item.id === packageSize.type,
                            'active-package': item.id === packageSize.type
                          }"
                          class="item-package-proof w-100 color-black"
                        >
                          <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" />
                          <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" />
                          <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <p>{{ item.description }}</p></a
                        >
                      </li>
                      <li class="indicator" style="left: 0px; right: 419px"></li>
                    </ul>
                  </div>
                </article>
              </div>
              <div class="content-package-information-result">
                <div v-for="item in listPackageSize" v-bind:key="item.id" :id="item.id" class="c-content--box-shadow mb-1" v-show="item.id === packageSize.type">
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-content--information-package">
                      <h3>{{ item.title }}</h3>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div v-if="item.id !== 'Personalizado'" class="c-section--content-package-information">
                      <div class="item-package-information">
                        Alto:
                        <p>{{ item.packageSize.height }}cm</p>
                      </div>
                      <div class="item-package-information">
                        Ancho:
                        <p>{{ item.packageSize.width }}cm</p>
                      </div>
                      <div class="item-package-information">
                        Largo:
                        <p>{{ item.packageSize.lengths }}cm</p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row m-0">
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-model="packageSize.height" @keypress="isNumber($event)" />
                            <label for="" class="active">Alto</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-bind:value="packageSize.width" @keypress="isNumber($event)" v-on:input="packageSize.width = $event.target.value" />
                            <label for="" class="active">Ancho</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-model="packageSize.lengths" @keypress="isNumber($event)" />
                            <label for="" class="active">Largo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-content--range d-flex justify-content-between">
                      <!-- <form action="#" class="w-80">
                        <label for="">Peso (kg)</label>
                        <p class="range-field">
                          <input
                            v-model="packageSize.weight"
                            type="range"
                            id="test5"
                            min="0"
                            max="130"
                            class="m-0"
                          /><span class="thumb"
                            ><span class="value"></span
                          ></span>
                        </p>
                      </form> -->
                      <div class="c-modal--directions-body content w-40">
                        <label for="">Peso (kg)</label>
                        <div class="c-input input-field">
                          <input type="number" min="1" max="100" v-model="packageSize.weight" />
                          <label for class="font-weight-light active">kg</label>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="description" />
                        <label for="" class="active">Descripción del producto a enviar</label>
                      </div>
                    </div>
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="productValue" @keypress="isNumber($event)" />
                        <label for="" class="active">Valor del producto (S/)</label>
                      </div>
                    </div>
                  </article>
                </div>

                <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top" data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/700. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                    <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1 tooltipped" data-position="bottom" data-tooltip="I am a tooltip" />Tenemos garantía de hasta
                    <span class="font-weight-bold">S/700 soles</span>
                  </div>
                  <div class="c-section--incognito icongnito-warning" v-if="amountMax"><img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto permitido, de continuar aceptas nuestras restricciones</div>
                </article>
                <article class="c-grid--services__item c-section--dateContent" v-if="false">
                  <div class="m-0 d-flex justify-content-center">
                    <a id="btnOpenModalRestriction" class="c-button c-button--five c-button--mobile-small waves-effect restriccion"> Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="c-popup-slide__footer">
        <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress1()" class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar btn-guarda1">Guardar</a>
          <button :data-popup-id="id" class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">Salir</button>
        </div>
      </div>
    </div>
    <button id="setSchaffPointMarker" v-on:click="setScharffPointMarkedSelect()" style="display: none"></button>
    <input id="scharffPointMarkerId" style="display: none" />
  </div>
</template>

<script>
import FormSectionTitle from "../Form/FormSectionTitle.vue";
import { getScharffPointFare, getScharffDistrics, getFare, getSizes } from "../../services/fare";
import CustomRadioButton from "../Form/CustomRadioButton.vue";
import CustomSearchSelect from "../Form/CustomSearchSelect.vue";
import CustomSelect from "../Form/CustomSelect.vue";
import DestinationContactButton from "../Button/DestinationContactButton.vue";
import ErrorMessage from "../ErrorMessage.vue";
import CustomCheckbox from "../Form/CustomCheckbox.vue";
import InputPackageAttribute from "../Form/InputPackageAttribute.vue";
import InformativeMessage from "../General/InformativeMessage.vue";
import ButtonDefinitive from "../Button/ButtonDefinitive.vue";
import InputText from "../Form/InputText.vue";
import ShippingCost from "../General/ShippingCost.vue";
import utils from "../../commons/mixins/utils";
import PackageSizes from "@/components/Shared/PackageSizes.vue";

import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import Map from "../../commons/mixins/maps";

function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default {
  components: {
    FormSectionTitle,
    CustomSearchSelect,
    CustomSelect,
    DestinationContactButton,
    ErrorMessage,
    CustomCheckbox,
    InputPackageAttribute,
    InformativeMessage,
    ButtonDefinitive,
    InputText,
    ShippingCost,
    CustomRadioButton,
    PackageSizes,

    InputDistrict,
  },
  name: "ModalScharffPointType2PackageV2",
  props: {
    id: String
  },
  data() {
    return {
      destinationDistrictId: '',
      destinationPuntoScharff: '',
      reference: '',
      productDescription: '',
      destinationDistrictData: {},
      selectedScharffPointData: {},
      tryToAddPackage: false,
      isAFragileProduct: false,
      scharffPointsList: [],
      maxLength: null,
      maxWeight: null,
      maxVolumetricWeight: null,
      packageWeight: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageDeep: 0,
      packageValue: 0,
      adicionalInformation: '',
      shippingCostIsLoading: false,
      shippingCost: 0,
      package: {
        hasValueCalculable: false,
        isAddableToList: false
      },
      isShippingPaidAtDestination: false,
      sizeList: [],
      sizeSelected: {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      },



      map: null,
      scharffPointVaule: "",
      lstScharffPoint: [],
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Pequeno",
          title: "Paquete Pequeño",
          description: "PEQUEÑO",
          imgUrl: "~@/assets/img/icon/icon-small.svg",
          packageSize: {
            height: 10, // Altura
            lengths: 12, // Largo
            width: 25, // Ancho
            maxWeight: 20 // Peso
          }
        },
        {
          id: "Mediano",
          title: "Paquete Mediano",
          description: "MEDIANO",
          imgUrl: "~@/assets/img/icon/icon-medium.svg",
          packageSize: {
            height: 20, // Altura
            lengths: 23, // Largo
            width: 38, // Ancho
            maxWeight: 60
          }
        },
        {
          id: "Grande",
          title: "Paquete Grande",
          description: "GRANDE",
          imgUrl: "~@/assets/img/icon/icon-grand.svg",
          packageSize: {
            height: 35, // Altura
            lengths: 50, // Largo
            width: 52, // Ancho
            maxWeight: 130
          }
        }
      ],
      packageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      defaultPackageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      contact: null,
      description: null,
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
        additionalAddress: ""
      },
      districtId: 0,
      puntoScharffId: null,
      options: {
        data: {}
      }
    };
  },
  methods: {
    getScharffDistrics,
    getSizes,
    setDestinationDistrictData(districtData) { this.destinationDistrictData = districtData },
    async checkValues() {
      const {
        destinationDistrictId, exceedsVolumetricWeight,
        DestinationContact, productDescription, packageWeight, maxLength, exceedsWeightLimit,
        packageWidth, packageHeight, packageDeep, packageValue,
        destinationPuntoScharff, hasLimaOrCallaoDistrict
      } = this

      const hasValueCalculable = (
        destinationDistrictId && hasLimaOrCallaoDistrict &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsVolumetricWeight
      )

      // this.package.hasValueCalculable = hasValueCalculable
      // if (hasValueCalculable) await this.calculateCost()
      // else this.shippingCost = 0

      this.package.isAddableToList = (
        hasValueCalculable &&
        packageValue &&
        destinationPuntoScharff &&
        this.shippingCost > 0 &&
        DestinationContact &&
        productDescription &&
        !this.shippingCostIsLoading
      )
    },
    async checkValuesToCalculate() {
      const {
        destinationDistrictId, packageWeight, packageWidth, packageHeight, packageDeep,
        exceedsVolumetricWeight, maxLength, exceedsWeightLimit, hasLimaOrCallaoDistrict
      } = this

      const hasValueCalculable = (
        destinationDistrictId && hasLimaOrCallaoDistrict &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsVolumetricWeight
      )

      this.package.hasValueCalculable = hasValueCalculable
      if (hasValueCalculable) await this.calculateCost()
      else this.shippingCost = 0
      this.shippingCostIsLoading = false
    },
    async calculateCost() {
      this.shippingCostIsLoading = true
      try {
        const fare = await getFare({
          "fareItems": [
            {
              "originDistrictId": this.originDistric.districtId.id,
              "destinationDistrictId": this.destinationDistrictId,
              "packageWidth": this.packageWidth,
              "packageHeight": this.packageHeight,
              "packageWeight": this.packageWeight,
              "packageDeep": this.packageDeep,
              "serviceType": 4, // puntos
              "sizeId": this.sizeSelected.tallaId,
              "shippingTypeId": 2 // pick up
            }
          ]
        })
        this.shippingCost = fare.data.totalFare
      } catch (e) {
        this.$swal({
          title: "Error!",
          text: e,
          icon: "error",
        })
      } finally {
        this.shippingCostIsLoading = false
      }
    },
    async getScharffPoints() {
      this.$store
        .dispatch("GET_SCHARFF_POINT", this.destinationDistrictId)
        .then((response) => {
          this.scharffPointsList = response;
          this.scharffPointsList.forEach((item) => {
            this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2");
          })
        })
    },
    setMarketV2(point) {
      const { DepartamentoTexto, ProvinciaTexto, DistritoTexto } = point
      if (this.isShippingPaidAtDestination) {
        this.setDestinationDistrictData({
          departmentId: point.DepartamentoId,
          id: point.DistritoId,
          provinceId: point.ProvinciaId,
          text: utils.methods.toCapitalCase(`${DepartamentoTexto} - ${ProvinciaTexto} - ${DistritoTexto}`),
        })
      }
      this.destinationPuntoScharff = point.ScharffPointId
      this.destinationDistrictId = point.DistritoId
      this.selectedScharffPointData = point
      this.map.centerMap({
        lat: point.Location.Lat,
        lng: point.Location.Lng
      })
    },
    handleAddPackage() {
      this.tryToAddPackage = true
      if(!this.package.isAddableToList) {
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: "warning",
          confirmButtonText: "Aceptar"
        })
        return
      }

      const allPackageData = {
        index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
        item: {
          description: this.productDescription,
          destinationAddress: {
            additionalAddress: this.reference,
            contact: this.DestinationContact,
            description: this.address,
            districtId: {
              id: this.destinationDistrictData.id,
              text: this.destinationDistrictData.text,
              textToFinded: this.destinationDistrictData.textToFind,
            },
            lat: null,
            lng: null,
            puntoScharffId: this.selectedScharffPointData
          },
          discountFare: null,
          fare: this.shippingCost,
          packageSize: {
            height: this.packageHeight,
            lengths: this.packageDeep,
            maxWeight: null,
            type: null, // lo que era "pequeño", "mediano", etc
            weight: this.packageWeight,
            width: this.packageWidth
          },
          fragil: this.isAFragileProduct,
          isShippingPaidAtDestination: this.isShippingPaidAtDestination,
          productValue: this.packageValue,
          sizeSelected: this.sizeSelected,
          shippingTypeId: 2
        },
      }
      this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", allPackageData);
      const popup = document.getElementById(this.id)
      popup.classList.remove("is-active");

      this.trackEvent('click_puntos_recojo_guardar_paquete', 'pickup')
    },
    getScharffPointsWithDestinationPayment() {
      this.$store.dispatch('GET_SCHARFF_POINT_WITH_DESTINATION_PAYMENT').then(resp => {
        this.scharffPointsList = resp
        resp.forEach(item => {
          this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2")
        })
      })
    },
    async getSizesForSharfPoints(type) {
      const sizes = await this.getSizes(type)
      const personalizedSize = sizes.find(size => size.codigo === 'P')
      this.maxLength = personalizedSize.alto
      this.maxWeight = personalizedSize.pesoMaximo
      this.maxVolumetricWeight = personalizedSize.pesoMaximo * 5000
      this.sizeList = sizes
    },
    handleSizeSelected(size) {
      this.sizeSelected = size
      if (size.codigo === 'P') {
        this.packageWeight = 0
        this.packageWidth = 0
        this.packageHeight = 0
        this.packageDeep = 0
      } else {
        this.packageWeight = size.pesoMaximo
        this.packageWidth = size.ancho
        this.packageHeight = size.alto
        this.packageDeep = size.profundidad
      }
    },


    setMarket() {
      this.map.centerMap({
        lat: this.puntoScharffId.Location.Lat,
        lng: this.puntoScharffId.Location.Lng
      });
    },
    async setScharffPointMarkedSelect() {
      const id = document.getElementById("scharffPointMarkerId").value;
      let scharffPoint = null;
      this.lstScharffPoint.forEach((item) => {
        if (item.ScharffPointId.toString() === id) {
          scharffPoint = item;
        }
      });
      this.puntoScharffId = scharffPoint;
      document.getElementById("scharff-point").value = scharffPoint;
      this.initSelects();
    },
    async getScharffPointLst() {
      this.$store
        .dispatch("GET_SCHARFF_POINT", this.districtId.id)
        .then((response) => {
          this.lstScharffPoint = response;
        })
        .then(() => {
          this.lstScharffPoint.forEach((item) => {
            this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item);
          });
          this.initSelects();
        });
    },
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (this.packageSize.weight === null || this.packageSize.weight === 0 || this.packageSize.weight === "0") {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }
      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(`El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight / 10} kg`);
        return false;
      }
      if (this.description === null) {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (this.districtId === 0) {
        this.showError("Debe seleccionar el distrito de las opciones ");
        return false;
      }
      if (this.puntoScharffId === 0) {
        this.showError("Debe de ingresar el punto sharf");
        return false;
      }
      if (this.DestinationContact === null) {
        this.showError("Debe de ingresar los datos del contacto");
        return false;
      }
      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageType: destination.packageSize.type === "Pequeno" ? "Pequeño" : destination.packageSize.type,
        PackageValue: destination.productValue,
        AcceptInsurance: false, // No se usa Seguro
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageWeight: destination.packageSize.weight,
        PackageDeep: destination.packageSize.lengths,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        IsUsingSubscription: false
      };
      return fareRequest;
    },
    saveDestinationAddress1() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              additionalAddress: this.destinationAddress.additionalAddress,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              puntoScharffId: this.puntoScharffId,
              contact: this.DestinationContact
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            discountFare: null
          }
        };

        const obj = this.setModel(this.origin, destinationTemp.item);
        const responseFare = getScharffPointFare(obj)
          .then((response) => {
            if (response.status == 200) {
              destinationTemp.item.fare = response.data.totalFare;
              const destination = JSON.parse(JSON.stringify(destinationTemp));
              this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", destination);
              this.clearData();
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          });
        this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);

        const btnPopupSlideClose = document.querySelectorAll(".btn-guarda1");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        this.packageSize.weight = item.packageSize.maxWeight;
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit("updateAddressInput", this.destinationAddress.description);
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite = document.querySelector("#modal-contact-destination");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async setDestinationDistrict2(id) {
      this.districtId = id;
      await this.getScharffPointLst();
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      // this.saveDestinationAddress();
      this.openContactModal("destination");
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      // this.saveDestinationAddress();
      this.openContactModal("destination");
    },
    async clearData() {
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
      this.destinationDistrictId = ''
      this.scharffPointsList = []
      this.destinationPuntoScharff = ''
      this.address = ''
      this.reference = ''
      this.productDescription = ''
      this.isAFragileProduct = false
      this.isShippingPaidAtDestination = false
      this.packageWeight = 0
      this.packageWidth = 0
      this.packageHeight = 0
      this.packageDeep = 0
      this.packageValue = 0
      this.adicionalInformation = ''
      this.package = {
        hasValueCalculable: false,
        isAddableToList: false
      }
      this.shippingCostIsLoading = false
      this.shippingCost = 0
      this.tryToAddPackage = false
      this.destinationDistrictData = {}
      this.selectedScharffPointData = {}
      this.sizeSelected = {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      }
    },
    setData(destination) {
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destination.destinationAddress.contact);
      this.isShippingPaidAtDestination = destination.isShippingPaidAtDestination
      this.destinationPuntoScharff = destination.destinationAddress.puntoScharffId?.ScharffPointId || ''
      this.selectedScharffPointData = destination.destinationAddress.puntoScharffId
      this.address = destination.destinationAddress.description
      this.reference = destination.destinationAddress.additionalAddress
      this.productDescription = destination.description
      this.packageWeight = destination.packageSize.weight
      this.packageWidth = destination.packageSize.height
      this.packageHeight = destination.packageSize.lengths
      this.packageDeep = destination.packageSize.width
      this.packageValue = destination.productValue
      // this.adicionalInformation = ''
      this.isAFragileProduct = destination.fragil
      this.package = {
        hasValueCalculable: true,
        isAddableToList: true
      }
      this.shippingCostIsLoading = false
      this.shippingCost = destination.fare
      this.tryToAddPackage = false

      this.sizeSelected = destination.sizeSelected

      setTimeout(() => {
        if (destination.isShippingPaidAtDestination) {
          this.setMarketV2(destination.destinationAddress.puntoScharffId)
          this.destinationPuntoScharff = destination.destinationAddress.puntoScharffId?.ScharffPointId || ''
        } else {
          this.$refs.CustomSelectComponentRef.selectElementFromOutside(destination.destinationAddress.districtId)
        }
      }, 100)
    }
  },
  created () {
    this.$watch(() => [
      this.destinationDistrictId,
      this.destinationPuntoScharff,
      this.address,
      this.reference,
      this.DestinationContact,
      this.productDescription,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
      this.packageValue,
      this.shippingCost,
    ], () => {
      this.checkValues()
    })
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ], debounce(this.checkValuesToCalculate, 300))
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ],
    () => {
      this.shippingCostIsLoading = true
    })
    this.$watch('destinationDistrictId', value => {
      if (value && !this.isShippingPaidAtDestination) {
        this.getScharffPoints()
      }
    });
    this.$watch('isShippingPaidAtDestination', value => {
      this.destinationDistrictId = ''
      this.destinationPuntoScharff = ''
      this.destinationDistrictData = {}
      this.selectedScharffPointData = {}
      if(value) {
        this.getScharffPointsWithDestinationPayment()
      } else {
        this.scharffPointsList = []
      }
    })
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      const elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems);
    });
    this.map = new Map();
    this.map.initMap("mp_ps_type2");
    this.$eventBus.$on("scharffPointClearDataDestinationType2Modal", () => {
      this.clearData();
    });
    this.$eventBus.$on("scharffPointDataDestinationType2Modal", (destination) => {
      this.setData(destination);
    });
    this.getSizesForSharfPoints('P')
  },
  computed: {
    hasLimaOrCallaoDistrict() {
      const originDistrict = `${this.originDistric.districtId?.text || ''}`
      const destinationDistrict = this.destinationDistrictData?.text || ''
      const originAndDestinationDistricts = `${originDistrict}${destinationDistrict}`
      return originDistrict && destinationDistrict ? originAndDestinationDistricts.toLowerCase().includes('lima') || originAndDestinationDistricts.toLowerCase().includes('callao') : true
    },
    originDistric() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    exceedsWeightLimit() {
      return this.packageWeight > this.maxWeight
    },
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },
    volumetricWeight() {
      const a = this.packageWidth
      const b = this.packageHeight
      const c = this.packageDeep
      return a * b * c
    },
    exceedsVolumetricWeight() {
      if (this.sizeSelected.codigo !== 'P') return false
      return this.volumetricWeight > this.maxVolumetricWeight
    },
    volumetricWeightMsg() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }); 
      const maxValue = this.maxVolumetricWeight
      const currentValue = this.volumetricWeight
      return `El volumen máximo por paquete en el servicio Puntos Sharf es ${formatter.format(maxValue)} cm³. Has ingresado un volumen de ${formatter.format(currentValue)} cm³.`
    },

    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    }
  }
};
</script>

<style>
@import '../../styles/09-componentsv2/addPackage.scss';

</style>