<template>
  <div id='modalSearch' class='c-modal modal' tabindex='0'>
    <div class='c-modal--directions-title'>
      <h2>Buscar servicios realizados</h2>
    </div>
    <div class='c-section--content-modal'>
      <article class='c-grid--services__item m-1'>
        <div class='c-section--content-date-options'>
          <div class='c-collection collection'>
            <!-- <div data-img='svg-dni' class='collection-item'>
              <label class='c-radio-buttons c-rb-item-type-doc'>
                <input v-model='type' value='1' name='option-type-doc' type='radio' checked='checked' />
                <span>Envío express</span>
              </label>
            </div> -->
            <div data-img='svg-docImmigration' class='collection-item'>
              <label class='c-radio-buttons c-rb-item-type-doc'>
                <input v-model='type' value='2' name='option-type-doc' type='radio' />
                <span>Envío programados</span>
              </label>
            </div>
            <!-- <div data-img='svg-other' class='collection-item'>
              <label class='c-radio-buttons c-rb-item-type-doc'><input v-model='type' value='3' name='option-type-doc' type='radio' />
                <span>Compra y trae</span>
              </label>
            </div> -->
            <div data-img='svg-other' class='collection-item'>
              <label class='c-radio-buttons c-rb-item-type-doc'>
                <input v-model='type' value='4' name='option-type-doc' type='radio' />
                <span>Punto Sharf</span>
              </label>
            </div>
            <div data-img='svg-other' class='collection-item'>
              <label class='c-radio-buttons c-rb-item-type-doc'>
                <input v-model='type' value='' name='option-type-doc' type='radio' />
                <span>Todos</span>
              </label>
            </div>
          </div>
        </div>
      </article>
      <div class='c-input input-field'>
        <input type='date' v-model='startDate' />
        <label for=''>Fecha Inicio</label>
      </div>
      <div class='c-input input-field'>
        <input type='date' v-model='endDate' />
        <label for=''>Fecha Fin</label>
      </div>
      <div class='c-modal--directions-footer m-1'>
        <a
          v-on:click='search()'
          href='javascript:void(0)'
          class='c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect'
        >
          Filtrar
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: '',
      startDate: null,
      endDate: null,
    }
  },
  methods: {
    async search() {
      this.showLoading()

      const payload = {
        serviceType: this.type,
        startDate: this.startDate,
        endDate: this.endDate,
        page: 1
      }
      this.$store.dispatch('UPDATE_PAGINATION_DETAILS', payload)
      this.$store.dispatch('HISTORY_FETCH_ORDERS').then(() => {
        this.$swal.close()
      })
      .catch(error => {
        this.showError(error)
      })
    },
  },
}
</script>
<style lang=''></style>
