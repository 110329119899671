<template>
  <div class="informative-message-container">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10 9.583v4.167m0-7.492.008-.009M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666" stroke="#32006E" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="span-message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Mensaje'
    },
  },
}
</script>

<style lang="scss" scoped>
.informative-message-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
  gap: 8px;

  background: #F4F2FF;
  border-radius: 8px;

  svg {
    min-width: 20px;
    min-height: 20px;
  }

  .span-message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #32006E;
  }
}
</style>