<template>
  <div class="c-scrollbar__modal-full">
    <!-- <div class="header__flat_rate">Tarifa plana de S/ 12.90 dentro de Lima Metropolitana hasta 10 kg *</div> -->
    <div class="col s12">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col section-inputs pt-2">
          <div style="font-weight: 600; font-size: 1.5em; text-align: center;" class="c-title pb-4 pt-4">
            Cotiza envíos a nivel nacional
          </div>
          <div>
            <div style="padding-top: 40px;" class="text-l-bold pb-4">Ruta de envío</div>
            <CustomSearchSelect
              v-model="originDistrictId"
              :repoSearch="getScharffDistrics"
              label="Origen"
              @setSelectedDisctrict="setOriginDistrict"
              placeholder="Departamento, Provincia, Distrito"
              errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
              :showEmptyInputError="emptyErrorFor.originDistrictId"
            />
            <ErrorMessage
              v-if="originDestinationError"
              style="margin-top: 3px"
              :message="errorOriginDestinationMsg"
              isOriginDestinationError
            />
            <div style="margin-bottom: 16px"></div>
            <CustomSearchSelect
              v-model="destinationDistrictId"
              :repoSearch="getScharffDistrics"
              label="Destino"
              @setSelectedDisctrict="setDestinationDistrict"
              placeholder="Departamento, Provincia, Distrito"
              errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
              :showEmptyInputError="emptyErrorFor.destinationDistrictId"
            />
            <ErrorMessage
              v-if="originDestinationError"
              style="margin-top: 3px"
              :message="errorOriginDestinationMsg"
              isOriginDestinationError
            />
          </div>
          <div style="padding-top: 40px;" class="text-l-bold pb-4">Medidas del paquete</div>
          <div class="package_measurements">
            <div class='pb-4'>
                <PackageSizes :sizeList='sizeList' :sizeSelectedId='sizeSelected.tallaId' @onSizeSelected='handleSizeSelected' />
            </div>
            <div v-if="sizeSelected.codigo === 'P'" class="attributes">
              <InputPackageAttribute
                label="Peso"
                measure="kg"
                v-model="packageWeight"
                missingErrorMessage="Ingresa el Peso."
                :showEmptyInputError="emptyErrorFor.packageWeight"
              />
            </div>
            <ErrorMessage :message="`El peso máximo permitido por paquete en el servicio Puntos Sharf es ${maxWeight} kg.`" v-show="exceedsWeightLimit"/>
            <div v-if="sizeSelected.codigo === 'P'" style="padding-top: 16px;" class="attributes">
              <InputPackageAttribute
                label="Largo"
                measure="cm"
                v-model="packageWidth"
                missingErrorMessage="Ingresa el Largo."
                :showEmptyInputError="emptyErrorFor.packageWidth"
                :max="maxLength"
              />
              <InputPackageAttribute
                label="Alto"
                measure="cm"
                v-model="packageHeight"
                missingErrorMessage="Ingresa el Alto."
                :showEmptyInputError="emptyErrorFor.packageHeight"
                :max="maxLength"
              />
              <InputPackageAttribute
                label="Ancho"
                measure="cm"
                v-model="packageDeep"
                missingErrorMessage="Ingresa el Ancho."
                :showEmptyInputError="emptyErrorFor.packageDeep"
                :max="maxLength"
              />
            </div>
            <ErrorMessage
              v-if="
                packageWidth <= maxLength &&
                packageHeight <= maxLength &&
                packageDeep <= maxLength
              "
              :message="volumetricWeightMsg"
              v-show="exceedsVolumetricWeight"
            />
          </div>

          <div class="pt-4 price_button">
            <div style="width: 100%;">
              <BlockResult
                :isExpanded=showPrice
                :text="total"
              />
            </div>
            <div class="button_overlapsed" :class="showPrice ? 'canceled' : ''">
              <ButtonDefinitive
                label="Calcular tarifa"
                @click="handleSubmit"
                :isLoading="isButtonLoading"
              />
              <!-- <AppButtonWithSpinner
                @clicked="handleSubmit"
                :isActive="isButtonActive"
                :isLoading="isButtonLoading"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <FlatRate cost="S/ 12.90" route="programmed" />
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import zones from "@/domain/data/zones.js";
import BlockResult from "@/views/components/general/app-block-result.vue";
import { getFare, getScharffDistrics, getSizes } from "../../services/fare";
import FlatRate from "../components/general/FlatRate.vue";
import ErrorMessage from "../../components/ErrorMessage.vue";
import InputPackageAttribute from "../../components/Form/InputPackageAttribute.vue";
import CustomSearchSelect from "../../components/Form/CustomSearchSelect.vue"
import ButtonDefinitive from "../../components/Button/ButtonDefinitive.vue";
import utils from "../../commons/mixins/utils";
import RutasDeEnvioImg from '../../assets/img/Rutas_de_envio.png'
import PackageSizes from "@/components/Shared/PackageSizes.vue";

export default {
  name: "QuoterCmp",
  components: {
    BlockResult,
    ButtonDefinitive,
    PackageSizes,
    FlatRate,
    ErrorMessage,
    InputPackageAttribute,
    CustomSearchSelect
  },
  data() {
    return {
      packageWeight: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageDeep: 0,
      sizeSelected: {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      },
      sizeList: [],
      maxLength: null,
      maxWeight: null,
      maxVolumetricWeight: null,
      emptyErrorFor: {
        originDistrictId: false,
        destinationDistrictId: false,
        packageWeight: false,
        packageWidth: false,
        packageHeight: false,
        packageDeep: false,
      },
      showPrice: false,
      originProvinceId: 0,
      destinationProvinceId: 0, 
      originDistrictId: '',
      destinationDistrictId: '',
      originDistrict: '',
      destinationDistrict: '',
      total: 0,
      zones: [],
      isSubmitted: false,
      showButtonSpinner: false,
      isButtonLoading: false
    };
  },
  methods: {
    getScharffDistrics,
    getSizes,
    async getSizesForProgramed() {
      const sizes = await this.getSizes()
      const personalizedSize = sizes.find(size => size.codigo === 'P')
      this.maxLength = personalizedSize.alto
      this.maxWeight = personalizedSize.pesoMaximo
      this.maxVolumetricWeight = personalizedSize.pesoMaximo * 5000
      this.sizeList = sizes
    },
    setOriginDistrict(district) {
      this.originDistrict = district?.district || district?.text || ''
      this.originProvinceId = district?.provinceId || 0
    },
    setDestinationDistrict(district) {
      this.destinationDistrict = district?.district || district?.text || ''
      this.destinationProvinceId = district?.provinceId || 0
    },
    handleSizeSelected(size) {
      this.sizeSelected = size
      if (size.codigo === 'P') {
        this.packageWeight = 0
        this.packageWidth = 0
        this.packageHeight = 0
        this.packageDeep = 0
      } else {
        this.packageWeight = size.pesoMaximo
        this.packageWidth = size.ancho
        this.packageHeight = size.alto
        this.packageDeep = size.profundidad
      }
    },
    setModel() {
      const fareRequest = {
        OriginDistrictId: this.originDistrictId.id,
        DestinationDistrictId: this.destinationDistrictId.id,
      };
      return fareRequest;
    },
    async handleSubmit() {
      if(!this.validateForm) {
        this.setErrorInEmptyInputs()
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: "warning",
          confirmButtonText: "Aceptar"
        })
        return
      }
      this.isButtonLoading = true
      try {
        const fare = await getFare({
          "fareItems": [
            {
              "originDistrictId": this.originDistrictId,
              "destinationDistrictId": this.destinationDistrictId,
              "packageWidth": this.packageWidth,
              "packageHeight": this.packageHeight,
              "packageWeight": this.packageWeight,
              "packageDeep": this.packageDeep,
              "serviceType": 2,
              "sizeId": this.sizeSelected.tallaId,
              "shippingTypeId": 4 // Programado

            }
          ]
        })
        this.total = fare.data.totalFare
        this.showPrice = true
      } catch (e) {
        this.$swal({
          title: "Error!",
          text: e,
          icon: "error",
        })
      } finally {
        this.isButtonLoading = false
      }
    },
    setErrorInEmptyInputs() {
      if(!this.originDistrictId) this.emptyErrorFor.originDistrictId = true
      if(!this.destinationDistrictId) this.emptyErrorFor.destinationDistrictId = true
      if(!this.volumetricWeight) this.emptyErrorFor.packageWeight = true
      if(!this.packageWidth) this.emptyErrorFor.packageWidth = true
      if(!this.packageHeight) this.emptyErrorFor.packageHeight = true
      if(!this.packageDeep) this.emptyErrorFor.packageDeep = true
    },
    validateInput() {
      if (!this.originDistrictId) {
        return false;
      }

      if (!this.destinationDistrictId) {
        return false;
      }

      return true;
    },
    closeModal() {
      const modal = document.querySelector("#modalCalculatorScheduledDelivery");
      const instance2 = M.Modal.getInstance(modal);
      instance2.close();
    },
  },
  computed: {
    originDestinationError() {
      if(this.destinationProvinceId && this.destinationProvinceId) {
        // const originAndDestinationDistricts = this.originDistrict + this.destinationDistrict
        /* const availableProvinces = [
          128, //Lima
          67, //Callao
        ];
        const isOriginProvinceAvailable = availableProvinces.includes(this.originProvinceId)
        const isDestinationProvinceAvailable = availableProvinces.includes(this.destinationProvinceId)
        return !isDestinationProvinceAvailable && !isOriginProvinceAvailable */
        if (utils.methods.validateInsideIsle(this.originDistrictId, this.destinationDistrictId)) {
          return false
        }
        return !utils.methods.validateOriginDestination(this.originProvinceId, this.destinationProvinceId);
      }
      return false
    },
    errorOriginDestinationMsg() {
      return `Por el momento, no realizamos la ruta de envío que ingresaste. <a href="${RutasDeEnvioImg}" target="_blank">Revisa nuestras rutas.</a>`
    },
    exceedsWeightLimit() {
      return this.packageWeight > this.maxWeight
    },
    volumetricWeight() {
      const a = this.packageWidth
      const b = this.packageHeight
      const c = this.packageDeep
      return a * b * c
    },
    exceedsVolumetricWeight() {
      if (this.sizeSelected.codigo !== 'P') return false
      return this.volumetricWeight > this.maxVolumetricWeight
    },
    volumetricWeightMsg() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }); 
      const maxValue = this.maxVolumetricWeight
      const currentValue = this.volumetricWeight
      return `El volumen máximo por paquete en el servicio Puntos Sharf es ${formatter.format(maxValue)} cm³. Has ingresado un volumen de ${formatter.format(currentValue)} cm³.`
    },
    validateForm() {
      if(!this.originDistrictId) return false
      if(!this.destinationDistrictId) return false
      if(this.packageWeight > this.maxWeight) return false
      if(this.exceedsVolumetricWeight) return false
      return ![this.packageWidth, this.packageHeight, this.packageDeep].some(number => number > this.maxLength || number <= 0)
    }
  },
  mounted() {
    this.$nextTick(async () => {
      // window.setTimeout(() => {
      //   this.initTabs();
        
      // }, 1000);

      this.zones = zones.map((item) => {
        const list = item.zones.map((zone) => ({
            title: `S/ ${zone.fare.programado.toFixed(2)}`,
            description: zone.regions,
          }));
        return {
          ...item,
          list,
        };
      });

      this.getSizesForProgramed()
    });
  },
};
</script>

<style lang="scss">
.button-link {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-scrollbar__modal-full{
  overflow-y: auto;

  @media screen and (min-width: 768px) {
    height: 1100px !important;
    max-height: 80vh !important;
  }
  @media screen and (max-width: 768px) {
    overflow-y: hidden !important;
  }
}

.c-scrollbar__modal-full::-webkit-scrollbar {
  height: 6px;
  width: 0.3em;
  transform: translateX(30px) !important;
}

.c-scrollbar__modal-full::-webkit-scrollbar-track {
  border-radius: 5px;
  transform: translateX(30px) !important;
}

.c-scrollbar__modal-full::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #abababcc;
  transform: translateX(30px) !important;
}
</style>
