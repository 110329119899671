<template>
  <div class="c-scrollbar__modal-full">
    <!-- <div class="header__flat_rate text-s-regular">Tarifa plana a S/ 9.90 dentro de Lima hasta 10 kg *</div> -->
    <div class="col s12">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col section-inputs pt-2">
          <div style="text-align: center;" class="c-title pb-4 pt-4 text-2xl-bold">
            Cotiza envíos a nivel nacional
          </div>
          <div class="text-l-bold pb-4 pt-4">Tipo de envío</div>
          <app-input-select
            :options="tipoEnvios"
            :default="'Selecciona un tipo de envío'"
            class="select"
            @input="setInput"
            :messageError="'Debe seleccionar el tipo de envío'"
            :isError="isSubmitted && tipoEnvio == -1"
          />
          <div v-show="tipoEnvio >= 0">
            <div style="padding-top: 40px;" class="text-l-bold pb-4">Ruta de envío</div>
            <div class="destiny_origin_selects">
              <CustomSearchSelect
                v-show="tipoEnvio === 0 || tipoEnvio === 2"
                v-model="originPointId"
                @setSelectedDisctrict="setOriginDistrict"
                :repoSearch="getScharffPoints"
                :isPoint=true
                label="Origen (Punto Sharf)"
                placeholder="Ubicación o nombre del punto"
                errorMessage="Ingresa la ubicación o nombre de un Punto Sharf y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="emptyErrorFor.originDistrictId"
              />
              <ErrorMessage
                style="margin-top: -10px;"
                :message="errorOriginDestinationMsg"
                v-if="(tipoEnvio === 0 || tipoEnvio === 2) && originDestinationError"
                isOriginDestinationError

              />
              <CustomSearchSelect
                v-show="tipoEnvio === 0"
                v-model="destinationDistrictId"
                @setSelectedDisctrict="setDestinationDistrict"
                :repoSearch="getScharffDistrics"
                label="Destino"
                placeholder="Departamento, Provincia, Distrito"
                errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="emptyErrorFor.destinationDistrictId"
              />
              <ErrorMessage
                style="margin-top: -10px;"
                :message="errorOriginDestinationMsg"
                v-if="(tipoEnvio === 0) && originDestinationError"
                isOriginDestinationError

              />
              <CustomSearchSelect
                v-show="tipoEnvio === 1"
                v-model="originDistrictId"
                :repoSearch="getScharffDistrics"
                label="Origen"
                @setSelectedDisctrict="setOriginDistrict"
                placeholder="Departamento, Provincia, Distrito"
                errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="emptyErrorFor.originDistrictId"
              />
              <ErrorMessage
                style="margin-top: -10px;"
                :message="errorOriginDestinationMsg"
                v-if="(tipoEnvio === 1) && originDestinationError"
                isOriginDestinationError

              />
              <CustomSearchSelect
                v-show="tipoEnvio === 1 || tipoEnvio === 2"
                v-model="destinationPointId"
                :repoSearch="getScharffPoints"
                :isPoint=true
                label="Destino (Punto Sharf)"
                @setSelectedDisctrict="setDestinationDistrict"
                placeholder="Ubicación o nombre del punto"
                errorMessage="Ingresa la ubicación o nombre de un Punto Sharf y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="emptyErrorFor.destinationDistrictId"
              />
              <ErrorMessage
                style="margin-top: -10px;"
                :message="errorOriginDestinationMsg"
                v-if="(tipoEnvio === 1 || tipoEnvio === 2) && originDestinationError"
                isOriginDestinationError

              />
            </div>

            <div style="padding-top: 40px;" class="text-l-bold pb-4">Medidas del paquete</div>
            <div class="package_measurements">
              <div class='pb-4'>
                <PackageSizes :sizeList='sizeList' :sizeSelectedId='sizeSelected.tallaId' @onSizeSelected='handleSizeSelected' />
              </div>
              <div v-if="sizeSelected.codigo === 'P'" class="attributes">
                <InputPackageAttribute
                  label="Peso"
                  measure="kg"
                  v-model="packageWeight"
                  missingErrorMessage="Ingresa el Peso."
                  :showEmptyInputError="emptyErrorFor.packageWeight"
                />
              </div>
              <ErrorMessage :message="`El peso máximo permitido por paquete en el servicio Puntos Sharf es ${maxWeight} kg.`" v-show="exceedsWeightLimit"/>
              <div v-if="sizeSelected.codigo === 'P'" style="padding-top: 16px;" class="attributes">
                <InputPackageAttribute
                  label="Largo"
                  measure="cm"
                  v-model="packageWidth"
                  missingErrorMessage="Ingresa el Largo."
                  :max="maxLength"
                  :showEmptyInputError="emptyErrorFor.packageWidth"
                />
                <InputPackageAttribute
                  label="Alto"
                  measure="cm"
                  v-model="packageHeight"
                  missingErrorMessage="Ingresa el Alto."
                  :max="maxLength"
                  :showEmptyInputError="emptyErrorFor.packageHeight"
                />
                <InputPackageAttribute
                  label="Ancho"
                  measure="cm"
                  v-model="packageDeep"
                  missingErrorMessage="Ingresa el Ancho."
                  :max="maxLength"
                  :showEmptyInputError="emptyErrorFor.packageDeep"
                />
              </div>
              <ErrorMessage
                v-if="
                  packageWidth <= maxLength &&
                  packageHeight <= maxLength &&
                  packageDeep <= maxLength
                "
                :message="volumetricWeightMsg"
                v-show="exceedsVolumetricWeight"
              />
            </div>
  
            <div class="pt-4 price_button">
              <div style="width: 100%;">
                <BlockResult
                  :isExpanded=showPrice
                  :text="total"
                />
              </div>
              <div class="button_overlapsed" :class="showPrice ? 'canceled' : ''">
                <ButtonDefinitive
                  label="Calcular tarifa"
                  @click="handleSubmit"
                  :isLoading="isButtonLoading"
                />
                  <!-- :isActive="isButtonActive" -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <FlatRate cost="S/ 9.90" route="puntoscharff" />
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import zones from "@/domain/data/zones.js";
import BlockResult from "@/views/components/general/app-block-result.vue";
import { getFare, getScharffDistrics, getScharffPoints, getSizes } from "../../services/fare";
import AppInputSelect from "../components/form/app-input-select.vue";
import ErrorMessage from "../../components/ErrorMessage.vue";
import InputPackageAttribute from "../../components/Form/InputPackageAttribute.vue";
import FlatRate from "../components/general/FlatRate.vue";
import CustomSearchSelect from "../../components/Form/CustomSearchSelect.vue"
import PackageSizes from "@/components/Shared/PackageSizes.vue";
import ButtonDefinitive from "../../components/Button/ButtonDefinitive.vue";
import utils from "../../commons/mixins/utils";
import RutasDeEnvioImg from '../../assets/img/Rutas_de_envio.png'

export default {
  name: "QuoterCmp",
  components: {
    BlockResult,
    AppInputSelect,
    ErrorMessage,
    InputPackageAttribute,
    FlatRate,
    CustomSearchSelect,
    PackageSizes,
    ButtonDefinitive
  },
  data() {
    return {
      shippingTypeId: null,
      packageWeight: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageDeep: 0,
      sizeSelected: {
        tallaId: null,
        codigo: '',
        descripcion: '',
        alto: null,
        ancho: null,
        profundidad: null,
        pesoMinimo: null,
        pesoMaximo: null,
      },
      sizeList: [],
      emptyErrorFor: {
        originDistrictId: false,
        destinationDistrictId: false,
        packageWeight: false,
        packageWidth: false,
        packageHeight: false,
        packageDeep: false,
      },
      maxLength: null,
      maxWeight: null,
      maxVolumetricWeight: null,
      showPrice: false,
      originPointId: '',
      destinationPointId: '',
      originDistrictId: '',
      destinationDistrictId: '',
      originProvinceId: 0,
      destinationProvinceId: 0, 
      originDistrict: '',
      destinationDistrict: '',
      total: 0,
      zones: [],
      textoInputOrigen: "Origen",
      textoInputDestino: "Destino",
      tipoEnvio: 0,
      isSubmitted: false,
      tipoEnvios: [
        "De Punto Sharf a domicilio",
        "De domicilio a Punto Sharf",
        "De Punto Sharf a Punto Sharf",
      ],
      isButtonLoading: false
    };
  },
  methods: {
    getScharffDistrics,
    getScharffPoints,
    getSizes,
    async getSizesForSharfPoints() {
      const sizes = await this.getSizes('P')
      const personalizedSize = sizes.find(size => size.codigo === 'P')
      this.maxLength = personalizedSize.alto
      this.maxWeight = personalizedSize.pesoMaximo
      this.maxVolumetricWeight = personalizedSize.pesoMaximo * 5000
      this.sizeList = sizes
    },
    setOriginDistrict(district) {
      if(district.isPoint) this.originDistrictId = district.distritoId

      this.originDistrict = district?.district || district?.text || ''
      this.originProvinceId = district?.provinceId || 0
    },
    setDestinationDistrict(district) {
      if(district.isPoint) this.destinationDistrictId = district.distritoId

      this.destinationDistrict = district?.district || district?.text || ''
      this.destinationProvinceId = district?.provinceId || 0
    },
    handleSizeSelected(size) {
      this.sizeSelected = size
      if (size.codigo === 'P') {
        this.packageWeight = 0
        this.packageWidth = 0
        this.packageHeight = 0
        this.packageDeep = 0
      } else {
        this.packageWeight = size.pesoMaximo
        this.packageWidth = size.ancho
        this.packageHeight = size.alto
        this.packageDeep = size.profundidad
      }
    },
    async handleSubmit() {
      if(!this.validateForm) {
        this.setErrorInEmptyInputs()
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: "warning",
          confirmButtonText: "Aceptar"
        })
        return
      }
      this.isButtonLoading = true
      try {
        const fare = await getFare({
          "fareItems": [
            {
              "originDistrictId": this.originDistrictId,
              "destinationDistrictId": this.destinationDistrictId,
              "packageWidth": this.packageWidth,
              "packageHeight": this.packageHeight,
              "packageWeight": this.packageWeight,
              "packageDeep": this.packageDeep,
              "serviceType": 4,
              "sizeId": this.sizeSelected.tallaId,
              "shippingTypeId": this.shippingTypeId
            }
          ]
        })
        this.total = fare.data.totalFare
        this.showPrice = true
      } catch (e) {
        this.$swal({
          title: "Error!",
          text: e,
          icon: "error",
          confirmButtonText: "Aceptar"
        })
      } finally {
        this.isButtonLoading = false
      }
    },
    setErrorInEmptyInputs() {
      if(!this.originDistrictId) this.emptyErrorFor.originDistrictId = true
      if(!this.destinationDistrictId) this.emptyErrorFor.destinationDistrictId = true
      if(!this.volumetricWeight) this.emptyErrorFor.packageWeight = true
      if(!this.packageWidth) this.emptyErrorFor.packageWidth = true
      if(!this.packageHeight) this.emptyErrorFor.packageHeight = true
      if(!this.packageDeep) this.emptyErrorFor.packageDeep = true
    },
    setInput(id) {
      this.originDistrictId = ''
      this.destinationDistrictId = ''
      const tipo = this.tipoEnvios.indexOf(id);
      this.tipoEnvio = tipo;
      this.setInputText(tipo);
    },
    setInputText(id) {
      switch (id) {
        case 0:
          this.textoInputOrigen = "Origen(PuntoScharff)";
          this.textoInputDestino = "Destino";
          this.shippingTypeId = 1
          break;
        case 1:
          this.textoInputOrigen = "Origen";
          this.textoInputDestino = "Destino(PuntoScharff)";
          this.shippingTypeId = 2
          break;
        case 2:
          this.textoInputOrigen = "Origen(PuntoScharff)";
          this.textoInputDestino = "Destino(PuntoScharff)";
          this.shippingTypeId = 3
          break;

        default:
          break;
      }
    },
    closeModal() {
      const modal = document.querySelector("#modalCalculatorScheduledDelivery");
      const instance2 = M.Modal.getInstance(modal);
      instance2.close();
    },
  },
  computed: {
    originDestinationError() {
      if(this.destinationProvinceId && this.destinationProvinceId) {
        // const originAndDestinationDistricts = this.originDistrict + this.destinationDistrict
        /* const availableProvinces = [
          128, //Lima
          67, //Callao
        ];
        const isOriginProvinceAvailable = availableProvinces.includes(this.originProvinceId)
        const isDestinationProvinceAvailable = availableProvinces.includes(this.destinationProvinceId)
        return !isDestinationProvinceAvailable && !isOriginProvinceAvailable */
        if (utils.methods.validateInsideIsle(this.originDistrictId, this.destinationDistrictId)) {
          return false;
        }
        return !utils.methods.validateOriginDestination(this.originProvinceId, this.destinationProvinceId);
      }
      return false
    },
    errorOriginDestinationMsg() {
      return `Por el momento, no realizamos la ruta de envío que ingresaste. <a href="${RutasDeEnvioImg}" target="_blank">Revisa nuestras rutas.</a>`
    },
    exceedsWeightLimit() {
      return this.packageWeight > this.maxWeight
    },
    volumetricWeight() {
      const a = this.packageWidth
      const b = this.packageHeight
      const c = this.packageDeep
      return a * b * c
    },
    exceedsVolumetricWeight() {
      if (this.sizeSelected.codigo !== 'P') return false
      return this.volumetricWeight > this.maxVolumetricWeight
    },
    volumetricWeightMsg() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }); 
      const maxValue = this.maxVolumetricWeight
      const currentValue = this.volumetricWeight
      return `El volumen máximo por paquete en el servicio Puntos Sharf es ${formatter.format(maxValue)} cm³. Has ingresado un volumen de ${formatter.format(currentValue)} cm³.`
    },
    validateForm() {
      if(!this.originDistrictId) return false
      if(!this.destinationDistrictId) return false
      if(this.packageWeight > this.maxWeight) return false
      if(this.exceedsVolumetricWeight) return false
      return ![this.packageWidth, this.packageHeight, this.packageDeep].some(number => number > this.maxLength || number <= 0)
    }
  },
  mounted() {
    this.$nextTick(async () => {
      // this.initTabs();

      this.zones = zones.map((item) => {
        const list = item.zones.map((zone) => ({
            title: `S/ ${zone.fare.puntos.toFixed(2)}`,
            description: zone.regions,
          }));
        return {
          ...item,
          list,
        };
      });

      this.getSizesForSharfPoints('P')
    });
  },
};
</script>

<style lang="scss">
.destiny_origin_selects {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.section-inputs {
  flex-wrap: wrap;
  width: 30rem;
  max-width: 80%;
  @media screen {
    max-width: 90%;
  }
}

.c-scrollbar__modal-full {
  overflow-x: hidden;
}

.spinner-layer,
.circle-clipper.left,
.circle-clipper.right,
.gap-patch {
  border-color: #8b2e2c;
}
.header__flat_rate {
  background-color: #FFEFEF;
  color: #68002B !important;
  text-align: center;
  padding: 12px 0;
  font-weight: 400;
}



.input-select-group {
  min-height: 48px;
  margin-top: 12px;
  /* padding-bottom: 12px; */
  /* padding-left: 16px; */
  border: 1px solid #737373;
  border-radius: 8px;
}
.with-sufix {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.with-sufix span {
  padding-right: 16px;
  color: #737373;
}
.input-primary,
.input-primary:focus {
  border-bottom: none !important;
}
.input-primary {
  padding: 1px 16px !important;
  margin-bottom: 0px!important;
}
.input-primary:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  padding-bottom: 0px!important;
}
.custom-select-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #1a1a1a;
  /* margin: 30px 0 !important; */
}

.package_measurements {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.attributes {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.price_button {
  height: 160px;
  display: grid;
  grid-template-rows: 80px 80px;
  align-items: center;
  justify-items: center;
}

.button_overlapsed {
  width: 200px !important;
  padding: 0 !important;
  transition: all .3s ease-out;
  transform: translate(0, -80px);
}
.button_overlapsed.canceled {
  transform: translate(0, 0);
}

@media (max-width: 480px) {
  .attributes {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 600px) {
  .button_overlapsed {
    width: 100% !important;
  }
}
</style>
