<template>
  <div class='package_summary_container'>

    <!-- Name in header -->
      <div class='package_summary_container--name_header'>
        <Icon name='Profile' />
        <div class='text-s-bold'>
          {{ packageData.destinationAddress.contact.name }}
        </div>
      </div>

      <!-- Body -->
      <div class='package_summary_container--body'>

        <!-- Attribute description -->
        <div class='package_summary_container--body--attribute'>
          <div class='attribute-icon'><Icon name='Delivery-Box' :size='20' /></div>
          <div class='text-s-regular attribute-text attribute-text'>
            {{ packageData.description }}
          </div>
        </div>
        <!-- Attribute measures -->
        <div class='package_summary_container--body--attribute'>
          <div class='attribute-icon'><Icon name='Ruler' /></div>
          <div class='text-s-regular attribute-text'>
            <template v-if="packageData.sizeSelected.codigo === 'P'">
              {{ packageData.packageSize.width }} cm x {{ packageData.packageSize.height }} cm x {{ packageData.packageSize.lengths }} cm | {{ packageData.packageSize.weight }} kg
            </template>
            <template v-else>
              {{ packageData.sizeSelected.descripcion }}
            </template>
          </div>
        </div>
        <!-- Attribute product value -->
        <div class='package_summary_container--body--attribute'>
          <div class='attribute-icon'><Icon name='Coins-2' /></div>
          <div class='text-s-regular attribute-text'>
            Valor: S/ {{ packageData.productValue }}
          </div>
        </div>
        <!-- Attribute address -->
        <div class='package_summary_container--body--attribute'>
          <div class='attribute-icon'><Icon name='Pin13' /></div>
          <div v-if='packageData.destinationAddress.puntoScharffId && packageData.destinationAddress.puntoScharffId.Name' class='text-s-regular attribute-text'>
            {{ packageData.destinationAddress.puntoScharffId.Name }}
          </div>
          <div v-else class='text-s-regular attribute-text'>
            {{ packageData.destinationAddress.description }} {{ packageData.destinationAddress.districtId.text.split(' - ')[2] }}, {{ packageData.destinationAddress.districtId.text.split(' - ')[1] }}
          </div>
        </div>
        <!-- Attribute ship cost -->
        <div class='package_summary_container--body--attribute'>
          <div class='attribute-icon'><Icon name='Truck-2' /></div>
          <div class='text-s-regular attribute-text'>
            Costo de envío: S/ {{ packageData.fare.toFixed(2) }} {{ packageData.isShippingPaidAtDestination ? '(pago en destino)' : '' }}
          </div>
        </div>

      </div>

     <!-- Action buttons -->
     <div class='package_summary_container--buttons'>
      <ButtonDefinitive
        v-if='includeEditButton'
        size='s'
        variant='tertiary'
        label='Editar'
        @click='onEdit'
      >
        <template v-slot:icon>
          <Icon name='Edit-Square' />
        </template>
      </ButtonDefinitive>
      <div class='delete-button' v-if='includeDeleteButton'>
        <ButtonDefinitive
          size='s'
          variant='tertiary'
          label='Eliminar'
          @click='onDelete'
        >
          <template v-slot:icon>
            <Icon name='Edit-Square' />
          </template>
        </ButtonDefinitive>
      </div>
     </div>

  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  components: {
    Icon,
    ButtonDefinitive,
  },
  props: {
    packageData: {
      type: Object,
      default: () => {}
    },
    packageDataIndex: { type: Number },
    includeEditButton: {
      type: Boolean,
      default: true
    },
    includeDeleteButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onEdit() {
      this.$emit('onEdit', this.packageData, this.packageDataIndex)
    },
    onDelete() {
      this.$emit('onDelete', this.packageDataIndex)
    },
  },
}
</script>

<style lang='scss'>
.package_summary_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  background: #FAFAFA;

  &--name_header {
    display: flex;
    padding: 16px 16px 16px 8px;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    border-bottom: 0.5px solid #D0D0D0;

    svg {path { stroke: #FF6568 }}
  }

  &--body {
    display: flex; flex-direction: column; gap: 0; width: 100%;

    &--attribute {
      display: flex;
      flex-grow: 1;
      .attribute-icon {
        padding: 12px 8px;
        svg { width: 20px; height: 20px }
      }
      .attribute-text {
        width: 100%;
        padding: 12px 8px;
        border-bottom: 0.5px solid #D0D0D0;
      }
    }
  }

  &--buttons {
    display: flex;
    .delete-button {
      svg {path { stroke: #B20000 }}
      button {span { color: #B20000 !important }}
    }
  }
}
</style>