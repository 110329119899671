import * as Sentry from "@sentry/vue";
import Vue from "vue"
import {  api2 } from "./api";
import store from "../stores/store";

const getScharffPointFare = async (data) => {
  try {
    const res = await api2.post("fare/scharffPointFare", data);
    
    return res.data;
  } catch (error) {
    
    return error.response;
  }
};

const getProgrammedFare = async (data) => {
  try {
    const res = await api2.post("fare/programmedFare", data);
    
    return res.data;
  } catch (error) {
    
    return error.response;
  }
};

const getFare = async (data) => {
  try {
    const res = await api2.post("fare", data);
    if (res.status !== 200) throw Error()
    return res.data
  } catch (error) {
    Vue.swal({
      title: "Error!",
      text: error.response.data.message,
      icon: "error",
      confirmButtonText: "Aceptar"
    })
    return error.response
  }
};

const getScharffDistrics = async (text) => {
  try {
    return store.dispatch("GET_DISTRICTS", text)
  } catch (error) {
    return error.response
  }
};
const getScharffPoints = async (text) => {
  const textLowCase = text.toLowerCase()
  try {
    const res = await api2.get(`puntoscharff/search?text=${textLowCase}`)
    const puntosList = res.data.data
    return puntosList.map(punto => {
      const { departamento, provincia, distrito, distritoId, nombre, direccion, provinciaId, puntoScharffId } = punto
      return {
        isPoint: true,
        id: puntoScharffId,
        distritoId,
        name: nombre,
        district: `${departamento} - ${provincia} - ${distrito}`,
        address: direccion,
        provinceId: provinciaId,
        labelAfterSelect: `${nombre}, ${distrito}, ${provincia}, ${departamento}`
      }
    })
  } catch (error) {
    return error.response
  }
};

const getSizes = async (type) => {
  const res = await api2.get(`sizes`, { params: { TipoPunto: type } })
  return res.data.data
}

export {
    getScharffPointFare,
    getProgrammedFare,
    getFare,
    getScharffDistrics,
    getScharffPoints,
    getSizes
};
